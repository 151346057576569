import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import MaterialTable from "material-table";
import Drawer from "../components/Drawer";
import { getAllSubjects } from "../store/actions/Subject";
import { Button } from "@material-ui/core";
import CustomSelect from "../components/CustomSelect";
import { getContentProviders } from "../store/actions/ContentProvider";
import { getBoardsByContentProvider } from "../store/actions/Board";
import { getStandardsByBoard } from "../store/actions/Standard";
import { getCoursesByStandard } from "../store/actions/Course";
import { getSubjectsByCourse } from "../store/actions/Subject";

const Subjects = (props) => {
  const dispatch = useDispatch();
  const contentProviders = useSelector(
    (state) => state.contentProviders.contentProviders
  );
  const boards = useSelector((state) => state.boards.boards);
  const standards = useSelector((state) => state.standards.standards);
  const courses = useSelector((state) => state.courses.courses);
  const subjects = useSelector((state) => state.subjects.subjects);

  const [contentProvider, setContentProvider] = useState("");
  const [board, setBoard] = useState("");
  const [standard, setStandard] = useState("");
  const [course, setCourse] = useState("");
  const [subject, setSubject] = useState("");

  const [standardError, setStandardError] = useState("");
  const [courseError, setCourseError] = useState("");
  const [subjectError, setSubjectError] = useState("");

  useEffect(() => {
    //dispatch(getAllSubjects());
    dispatch(getContentProviders());
  }, [dispatch]);

  //   const onClickAddUnits = () => {
  //     props.history.push({
  //       pathname: "/add-units",
  //     });
  //   };

  //console.log(boards);
  const onContentProviderChange = (e) => {
    e.preventDefault();
    setContentProvider(e.target.value);
    dispatch(getBoardsByContentProvider(e.target.value));
  };
  const onBoardChange = (e) => {
    e.preventDefault();
    setBoard(e.target.value);
    dispatch(getStandardsByBoard(e.target.value));
  };
  const onStandardChange = (e) => {
    e.preventDefault();
    setStandard(e.target.value);
    dispatch(getCoursesByStandard(e.target.value));
  };
  const onCourseChange = (e) => {
    e.preventDefault();
    setCourse(e.target.value);
    dispatch(getSubjectsByCourse(e.target.value));
  };
  // const onSubjectChange = (e) => {
  //   e.preventDefault();
  //   setSubject(e.target.value);
  //   setSubjectError("");
  // };
  const onClickToEditSubject = (rowData) => {
    //console.log(rowData);
    props.history.push({
      pathname: "/upload-subject-image",
      data: rowData,
    });
  };
  return (
    <div>
      <Drawer headerName="Subjects">
        <div style={{ maxWidth: "100%" }}>
          <div className="container">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flex: 1,
                flexGrow: 1,
                justifyContent: "flex-start",
              }}
            >
              <CustomSelect
                label="Content Provider"
                value={contentProvider}
                options={contentProviders}
                firstMenu="Select Content Providre"
                onChange={onContentProviderChange}
              />
              <CustomSelect
                label="Board"
                value={board}
                options={boards}
                firstMenu="Select Board"
                onChange={onBoardChange}
              />
              <CustomSelect
                label="Standard"
                value={standard}
                options={standards}
                firstMenu="Select Standard"
                onChange={onStandardChange}
              />
              <CustomSelect
                label="Course"
                value={course}
                options={courses}
                firstMenu="Select Course"
                onChange={onCourseChange}
              />
            </div>
            <MaterialTable
              columns={[
                {
                  title: "Image",
                  field: "imageUrl",
                  render: (rowData) => (
                    <img
                      src={rowData.imageUrl}
                      style={{ width: 40, height: 40 }}
                    />
                  ),
                },
                { title: "Subject", field: "name" },
                { title: "Description", field: "description" },
                { title: "Course", field: "course.name" },
              ]}
              actions={[
                {
                  icon: "edit",
                  tooltip: "Update Unit",
                  onClick: (event, rowData) => onClickToEditSubject(rowData),
                },
              ]}
              data={subjects}
              title="Subjects"
            />
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default Subjects;
