//import isEmpty from "../../validation/is-empty";
import {
  GET_CONCEPTS_BY_SUBUNIT_FAILURE,
  GET_CONCEPTS_BY_SUBUNIT_REQUEST,
  GET_CONCEPTS_BY_SUBUNIT_SUCCESS,
  CREATE_CONCEPTS_FAILURE,
  CREATE_CONCEPTS_SUCCESS,
  CREATE_CONCEPTS_REQUEST,
} from "../actions/Concept";

const initialState = {
  concepts: [],
  message: null,
  error: null,
  isLoading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CREATE_CONCEPTS_REQUEST:
    case GET_CONCEPTS_BY_SUBUNIT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case CREATE_CONCEPTS_SUCCESS:
    case GET_CONCEPTS_BY_SUBUNIT_SUCCESS:
      return {
        ...state,

        isLoading: false,
        concepts: action.payload,
      };
    case CREATE_CONCEPTS_FAILURE:
    case GET_CONCEPTS_BY_SUBUNIT_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
