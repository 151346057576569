import { combineReducers } from "redux";
import authReducer from "../store/reducers/Auth";
import Account from "../store/reducers/Account";
import User from "../store/reducers/User";

import Content from "../store/reducers/Content";
import Alert from "../store/reducers/Alert";
import ContentProvider from "../store/reducers/ContentProvider";
import Board from "../store/reducers/Board";
import Standard from "../store/reducers/Standard";
import Course from "../store/reducers/Course";
import Subject from "../store/reducers/Subject";
import Unit from "../store/reducers/Unit";
import Subunit from "../store/reducers/Subunit";
import Concept from "../store/reducers/Concept";
import Question from "../store/reducers/Question";
import DifficultyLevel from "../store/reducers/DifficultyLevel";
import ObjectiveType from "../store/reducers/ObjectiveType";

export default combineReducers({
  auth: authReducer,
  users: User,
  account: Account,
  content: Content,
  alert: Alert,
  contentProviders: ContentProvider,
  boards: Board,
  standards: Standard,
  courses: Course,
  subjects: Subject,
  units: Unit,
  subunits: Subunit,
  concepts: Concept,
  questions: Question,
  difficultyLevels: DifficultyLevel,
  objectiveTypes: ObjectiveType,
});
