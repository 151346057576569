import React, { useEffect, useState } from "react";
import Drawer from "../components/Drawer";
import CustomSelect from "../components/CustomSelect";
import CustomUnitSelect from "../components/CustomUnitSelect";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import Alert from "../components/Alert";
//import XLSX from "xlsx";
import { useDispatch, useSelector } from "react-redux";
import { Button, IconButton, Typography } from "@material-ui/core";
import { getAccountDetail } from "../store/actions/Account";
import { getAllSubjects } from "../store/actions/Subject";
import { createUnits, getUnitsBySubject } from "../store/actions/Unit";
import { getSubunitsByUnit } from "../store/actions/Subunit";
import { getContentProviders } from "../store/actions/ContentProvider";
import { getBoardsByContentProvider } from "../store/actions/Board";
import { getStandardsByBoard } from "../store/actions/Standard";
import { getCoursesByStandard } from "../store/actions/Course";
import { getSubjectsByCourse } from "../store/actions/Subject";
const AddUnits = (props) => {
  const dispatch = useDispatch();

  const contentProviders = useSelector(
    (state) => state.contentProviders.contentProviders
  );
  const boards = useSelector((state) => state.boards.boards);
  const standards = useSelector((state) => state.standards.standards);
  const courses = useSelector((state) => state.courses.courses);
  const subjects = useSelector((state) => state.subjects.subjects);

  const [contentProvider, setContentProvider] = useState("");
  const [board, setBoard] = useState("");
  const [standard, setStandard] = useState("");
  const [course, setCourse] = useState("");
  const [subject, setSubject] = useState("");

  const alert = useSelector((state) => state.alert.alert);
  const user = useSelector((state) => state.auth.user);
  const account = useSelector((state) => state.account.account);

  const [subjectError, setSubjectError] = useState("");
  const [unitError, setUnitError] = useState("");

  const [unitsList, setSubunitsList] = useState([
    { number: "", sequence: "", name: "", description: "" },
  ]);

  const row = { display: "flex", flexDirection: "row", alignContent: "center" };

  useEffect(() => {
    dispatch(getContentProviders());
  }, [dispatch]);
  if (alert !== null && alert.alertType == "success") {
    props.history.goBack();
  }

  const onContentProviderChange = (e) => {
    e.preventDefault();
    setContentProvider(e.target.value);
    dispatch(getBoardsByContentProvider(e.target.value));
  };
  const onBoardChange = (e) => {
    e.preventDefault();
    setBoard(e.target.value);
    dispatch(getStandardsByBoard(e.target.value));
  };
  const onStandardChange = (e) => {
    e.preventDefault();
    setStandard(e.target.value);
    dispatch(getCoursesByStandard(e.target.value));
  };
  const onCourseChange = (e) => {
    e.preventDefault();
    setCourse(e.target.value);
    dispatch(getSubjectsByCourse(e.target.value));
  };

  const onSubjectChange = (e) => {
    e.preventDefault();
    setSubject(e.target.value);
    dispatch(getUnitsBySubject(e.target.value));
    setSubjectError("");
  };

  // handle input change
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...unitsList];
    list[index][name] = value;
    setSubunitsList(list);
    setUnitError("");
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...unitsList];
    list.splice(index, 1);
    setSubunitsList(list);
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setSubunitsList([...unitsList, { number: "", sequence: "", name: "" }]);
  };

  // validation

  const validate = () => {
    if (subject == "") {
      setSubjectError("Subject is required");
      return false;
    }

    for (let i = 0; i < unitsList.length; i++) {
      if (
        unitsList[i].number == "" ||
        unitsList[i].sequence == "" ||
        unitsList[i].name == ""
      ) {
        setUnitError("Fill all the fields");
        return false;
      }
    }

    return true;
  };
  const onClickTocreateSubunits = () => {
    const isValid = validate();
    console.log("save units");
    if (isValid) {
      dispatch(createUnits({ subjectId: subject, units: unitsList }));
      setSubject("")
    }
  };
  return (
    <div>
      <Drawer
        headerName="Add Units"
        user={account !== null && account.firstName}
      >
        {alert && <Alert severity={alert.alertType} message={alert.message} />}
        {unitError && (
          <div style={{ padding: 8, backgroundColor: "red" }}>
            <Typography variant="body1">{unitError}</Typography>
          </div>
        )}
        <div style={{ width: "100%" }}>
          <div style={{ ...row }}>
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flex: 1,
                  flexGrow: 1,
                  justifyContent: "space-between",
                }}
              >
                <CustomSelect
                  label="Content Provider"
                  value={contentProvider}
                  options={contentProviders}
                  firstMenu="Select Content Providre"
                  onChange={onContentProviderChange}
                />
                <CustomSelect
                  label="Board"
                  value={board}
                  options={boards}
                  firstMenu="Select Board"
                  onChange={onBoardChange}
                />
                <CustomSelect
                  label="Standard"
                  value={standard}
                  options={standards}
                  firstMenu="Select Standard"
                  onChange={onStandardChange}
                />
                <CustomSelect
                  label="Course"
                  value={course}
                  options={courses}
                  firstMenu="Select Course"
                  onChange={onCourseChange}
                />

                <CustomSelect
                  required={true}
                  label="Subjects"
                  value={subject}
                  options={subjects}
                  error={subjectError}
                  firstMenu="Select Subject"
                  onChange={onSubjectChange}
                />
              </div>
            </div>
          </div>
          <div>
            {unitsList.map((un, i) => {
              return (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "start",
                  }}
                  key={i.toString()}
                >
                  <input
                    style={{ margin: 8 }}
                    type="text"
                    placeholder="Unit Number"
                    onChange={(e) => handleInputChange(e, i)}
                    name="number"
                    value={un.number}
                    required
                  />

                  <input
                    style={{ margin: 8 }}
                    type="number"
                    placeholder="Unit Sequence"
                    onChange={(e) => handleInputChange(e, i)}
                    name="sequence"
                    value={un.sequence}
                    required
                  />

                  <input
                    style={{ margin: 8 }}
                    type="text"
                    placeholder="Name"
                    onChange={(e) => handleInputChange(e, i)}
                    name="name"
                    value={un.name}
                  />
                  <input
                    style={{ margin: 8, width: 300 }}
                    type="text"
                    placeholder="Description"
                    onChange={(e) => handleInputChange(e, i)}
                    name="description"
                    value={un.description}
                  />
                  {unitsList.length !== 1 && (
                    <IconButton
                      onClick={() => handleRemoveClick(i)}
                      aria-label="delete"
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                  {unitsList.length - 1 === i && (
                    <IconButton onClick={handleAddClick} aria-label="add">
                      <AddIcon />
                    </IconButton>
                  )}
                </div>
              );
            })}
            <div
              style={{
                display: "flex",
                margin: 8,
              }}
            >
              <Button
                size="small"
                variant="contained"
                color="primary"
                disableElevation
                onClick={onClickTocreateSubunits}
              >
                Create
              </Button>
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default AddUnits;
