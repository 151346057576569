import React, { useEffect, useState } from "react";
import Drawer from "../components/Drawer";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip, Fab, Typography, Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import CustomSelect from "../components/CustomSelect";
import CustomUnitSelect from "../components/CustomUnitSelect";
import Alert from "../components/Alert";
import Theme from "../Theme";
import XLSX from "xlsx";
import { getContentProviders } from "../store/actions/ContentProvider";
import { getBoardsByContentProvider } from "../store/actions/Board";
import { getStandardsByBoard } from "../store/actions/Standard";
import { getCoursesByStandard } from "../store/actions/Course";
import { getAllSubjects, getSubjectsByCourse } from "../store/actions/Subject";
import { getUnitsBySubject } from "../store/actions/Unit";
import { getUsers, assignContent } from "../store/actions/User";

import { uploadQuestionsFile } from "../store/actions/Question";
import { getAccountDetail } from "../store/actions/Account";

const AssignWork = (props) => {
  const dispatch = useDispatch();
  const contentProviders = useSelector(
    (state) => state.contentProviders.contentProviders
  );
  const boards = useSelector((state) => state.boards.boards);
  const standards = useSelector((state) => state.standards.standards);
  const courses = useSelector((state) => state.courses.courses);
  const subjects = useSelector((state) => state.subjects.subjects);
  const units = useSelector((state) => state.units.units);
  const users = useSelector((state) => state.users.users);
  const user = useSelector((state) => state.auth.user);
  const account = useSelector((state) => state.account.account);

  const alert = useSelector((state) => state.alert.alert);

  const [contentProviderId, setContentProviderId] = useState("");
  const [boardId, setBoardId] = useState("");
  const [standardId, setStandardId] = useState("");
  const [courseId, setCourseId] = useState("");
  const [subjectId, setSubjectId] = useState("");
  const [unitId, setUnitId] = useState("");
  const [userId, setUserId] = useState("");

  const [contentProviderError, setContentProviderError] = useState("");
  const [boardError, setBoardError] = useState("");
  const [standardError, setStandardError] = useState("");
  const [courseError, setCourseError] = useState("");
  const [subjectError, setSubjectError] = useState("");
  const [unitError, setUnitError] = useState("");
  const [userError, setUserError] = useState("");

  useEffect(() => {
    // dispatch(getQuestions(user.id));
    //dispatch(getAccountDetail(user.id));
    dispatch(getAllSubjects());
    dispatch(getContentProviders());
    dispatch(getUsers());
  }, [dispatch]);
  if (alert !== null && alert.alertType == "success") {
    props.history.goBack();
  }
  const onContentProviderChange = (e) => {
    e.preventDefault();
    setContentProviderId(e.target.value);
    dispatch(getBoardsByContentProvider(e.target.value));
    setContentProviderError("");
  };

  const onBoardChange = (e) => {
    e.preventDefault();
    setBoardId(e.target.value);
    dispatch(getStandardsByBoard(e.target.value));
    setBoardError("");
  };
  const onStandardChange = (e) => {
    e.preventDefault();
    setStandardId(e.target.value);
    dispatch(getCoursesByStandard(e.target.value));
    setStandardError("");
  };

  const onCourseChange = (e) => {
    e.preventDefault();
    setCourseId(e.target.value);
    dispatch(getSubjectsByCourse(e.target.value));
    setCourseError("");
  };
  const onSubjectChange = (e) => {
    e.preventDefault();
    setSubjectId(e.target.value);
    dispatch(getUnitsBySubject(e.target.value));
    setSubjectError("");
  };
  const onUnitChange = (e) => {
    e.preventDefault();
    setUnitId(e.target.value);
    setUnitError("");
  };

  const onUserChange = (e) => {
    e.preventDefault();
    setUserId(e.target.value);
    setUserError("");
  };
  const validate = () => {
    if (userId === "") {
      setUserError("Select User");
      return false;
    }
    if (contentProviderId === "") {
      setContentProviderError("Select Content Provider");
      return false;
    }
    if (boardId === "") {
      setBoardError("Select Board");
      return false;
    }
    if (standardId === "") {
      setStandardError("Select Standard");
      return false;
    }
    if (courseId === "") {
      setCourseError("Select Course");
      return false;
    }

    if (subjectId === "") {
      setSubjectError("Select Subject");
      return false;
    }
    if (unitId === "") {
      setUnitError("Select Unit");
      return false;
    }

    return true;
  };
  const onClickAssign = (e) => {
    const isValid = validate();

    if (isValid) {
      console.log("assign work");
      const userData = {
        userId,
        contentProviderId,
        boardId,
        standardId,
        courseId,
        subjectId,
        unitId,
      };

      console.log(userData);
      dispatch(assignContent(userData));
    }
  };
  return (
    <div>
      <Drawer headerName="Assign Work" user={account !== null && account.name}>
        <div
          style={{
            //width: 800,
            alignItems: "center",
            //justifyContent: "center",
            //alignSelf: "center",
            //alignContent: "center",
            //flex: 1,
            // display: "flex",
            flexDirection: "column",
          }}
        >
          {alert && (
            <Alert severity={alert.alertType} message={alert.message} />
          )}
          <div>
            <div
              style={{
                marginLeft: 16,
                marginTop: 24,
                backgroundColor: "silver",
                padding: 8,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography style={{ marginRight: 16 }}>Assign Work</Typography>
            </div>
            <div
              style={{
                alignItems: "flex-start",
                justifyContent: "flex-start",
                // alignSelf: "center",
                display: "flex",
                flexDirection: "column",
                marginLeft: 8,
              }}
            >
              <CustomSelect
                required={true}
                label="Users"
                value={userId}
                options={users}
                error={userError}
                firstMenu="Select User"
                onChange={onUserChange}
                minWidth={300}
              />
              <CustomSelect
                required={true}
                label="Content Provider"
                value={contentProviderId}
                options={contentProviders}
                error={contentProviderError}
                firstMenu="Select Content Provider"
                onChange={onContentProviderChange}
                minWidth={300}
              />
              <CustomSelect
                required={true}
                label="Boards"
                value={boardId}
                options={boards}
                error={boardError}
                firstMenu="Select Board"
                onChange={onBoardChange}
                minWidth={300}
              />
              <CustomSelect
                required={true}
                label="Standards"
                value={standardId}
                options={standards}
                error={standardError}
                firstMenu="Select Standard"
                onChange={onStandardChange}
                minWidth={300}
              />
              <CustomSelect
                required={true}
                label="Courses"
                value={courseId}
                options={courses}
                error={courseError}
                firstMenu="Select Course"
                onChange={onCourseChange}
                minWidth={300}
              />
              <CustomSelect
                required={true}
                label="Subjects"
                value={subjectId}
                options={subjects}
                error={subjectError}
                firstMenu="Select Subject"
                onChange={onSubjectChange}
                minWidth={300}
              />
              <CustomUnitSelect
                required={true}
                label="Units"
                options={units}
                value={unitId}
                error={unitError}
                onChange={onUnitChange}
                firstMenu="Select Unit"
                minWidth={300}
              />

              <div
                style={{
                  margin: 8,
                }}
              >
                <Button
                  size="medium"
                  variant="contained"
                  color="primary"
                  disableElevation
                  onClick={onClickAssign}
                >
                  Assign
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default AssignWork;
