import React from "react";
import CheckIcon from "@material-ui/icons/Check";
import ToggleButton from "@material-ui/lab/ToggleButton";

const StandaloneToggleButton = (props) => {
  return (
    <div>
      <ToggleButton
        style={{
          height: 40,
          width: 40,
          borderWidth: props.error ? 2 : 1,
          borderColor: props.error ? "red" : "grey",
        }}
        value="check"
        selected={props.selected}
        onChange={props.onToggle}
      >
        {props.selected && <CheckIcon />}
      </ToggleButton>
    </div>
  );
};

export default StandaloneToggleButton;
