//import isEmpty from "../../validation/is-empty";
import {
  UPLOAD_QUESTIONS_FILE_FAILURE,
  UPLOAD_QUESTIONS_FILE_REQUEST,
  UPLOAD_QUESTIONS_FILE_SUCCESS,
} from "../actions/Question";

const initialState = {
  courses: [],
  message: null,
  error: null,
  isLoading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case UPLOAD_QUESTIONS_FILE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case UPLOAD_QUESTIONS_FILE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        message: action.payload,
      };
    case UPLOAD_QUESTIONS_FILE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
