import React from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import PeopleIcon from "@material-ui/icons/People";
import HomeWorkIcon from "@material-ui/icons/HomeWork";
import SubjectIcon from "@material-ui/icons/Subject";
import BallotIcon from "@material-ui/icons/Ballot";
import AssignmentIcon from "@material-ui/icons/Assignment";
import CategoryIcon from "@material-ui/icons/Category";
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import {
  Drawer,
  CssBaseline,
  AppBar,
  Toolbar,
  List,
  ListItemText,
  ListItem,
  Divider,
  Typography,
  IconButton,
  Button,
} from "@material-ui/core";

import { withRouter } from "react-router-dom";

import HomeIcon from "@material-ui/icons/Home";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { logout } from "../store/actions/Auth";
import { useDispatch, useSelector } from "react-redux";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    color: "white",
    backgroundColor: "#00796B",
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

const DrawerItem = (props) => {
  const { history } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const user = useSelector((state) => state.auth.user);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const onClickTogGoAdmin = (e) => {
    props.history.push({
      pathname: "/admin-panel",
    });
  };
  const drawerList = [
    {
      text: "View Questions",
      icon: <HomeIcon style={{ color: "white" }} />,
      onClick: () => history.push("/"),
    },
    {
      text: "Verify Questions",
      icon: <VerifiedUserIcon style={{ color: "white" }} />,
      onClick: () => history.push("/verify-question"),
    },
    {
      text: "Account",
      icon: <AccountCircleIcon style={{ color: "white" }} />,
      onClick: () => history.push("/account"),
    },
    {
      text: "Logout",
      icon: <ExitToAppIcon style={{ color: "white" }} />,
      onClick: () => {
        dispatch(logout());
      },
    },
  ];

  const adminDrawerList = [
    {
      text: "View Questions",
      icon: <HomeIcon style={{ color: "white" }} />,
      onClick: () => history.push("/"),
    },
    {
      text: "Verify Questions",
      icon: <VerifiedUserIcon style={{ color: "white" }} />,
      onClick: () => history.push("/verify-question"),
    },
    {
      text: "Users",
      icon: <PeopleIcon style={{ color: "white" }} />,
      onClick: () => history.push("/users"),
    },
    {
      text: "Assign work",
      icon: <HomeWorkIcon style={{ color: "white" }} />,
      onClick: () => history.push("/assign-work"),
    },
    // {
    //   text: "Content Providers",
    //   icon: <AccountCircleIcon style={{ color: "white" }} />,
    //   onClick: () => history.push("/content-providers"),
    // },
    // {
    //   text: "Boards",
    //   icon: <AccountCircleIcon style={{ color: "white" }} />,
    //   onClick: () => history.push("/boards"),
    // },
    // {
    //   text: "Courses",
    //   icon: <AccountCircleIcon style={{ color: "white" }} />,
    //   onClick: () => history.push("/courses"),
    // },
    {
      text: "Upload File",
      icon: <CloudUploadIcon style={{ color: "white" }} />,
      onClick: () => history.push("/upload-question-file"),
    },
    {
      text: "Subjects",
      icon: <SubjectIcon style={{ color: "white" }} />,
      onClick: () => history.push("/subjects"),
    },
    {
      text: "Units",
      icon: <CategoryIcon style={{ color: "white" }} />,
      onClick: () => history.push("/units"),
    },
    {
      text: "Subunits",
      icon: <AssignmentIcon style={{ color: "white" }} />,
      onClick: () => history.push("/subunits"),
    },
    {
      text: "Concepts",
      icon: <BallotIcon style={{ color: "white" }} />,
      onClick: () => history.push("/concepts"),
    },

    {
      text: "Account",
      icon: <AccountCircleIcon style={{ color: "white" }} />,
      onClick: () => history.push("/account"),
    },
    {
      text: "Logout",
      icon: <ExitToAppIcon style={{ color: "white" }} />,
      onClick: () => {
        dispatch(logout());
      },
    },
  ];

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          <div>
            <Typography variant="h6" noWrap>
              IMPRU - Content Portal - {props.headerName}
            </Typography>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List>
          {user.role == "ADMIN"
            ? adminDrawerList.map((item, index) => (
                <ListItem button key={item.text} onClick={item.onClick}>
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItem>
              ))
            : drawerList.map((item, index) => (
                <ListItem button key={item.text} onClick={item.onClick}>
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItem>
              ))}
        </List>
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.drawerHeader} />
        {props.children}
      </main>
    </div>
  );
};

export default withRouter(DrawerItem);
