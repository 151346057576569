import React, { useEffect, useState } from "react";
//import { OutTable, ExcelRenderer } from "react-excel-renderer";
//import Latex from "./Latex";
//import Mathjx from "./Mathjx";
import ReactSummernote from "react-summernote";
//import { uploadImage } from "../store/actions/File";
import { useSelector, useDispatch } from "react-redux";
import "react-summernote/dist/react-summernote.css";
import "react-summernote/lang/summernote-ru-RU"; // import styles

// Import bootstrap(v3 or v4) dependencies
import "bootstrap/js/dist/modal";
import "bootstrap/js/dist/dropdown";
import "bootstrap/js/dist/tooltip";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import $ from "jquery";
const jQuery = $;
window.jQuery = $;

require("bootstrap");

const AdvancedSummernote = (props) => {
  const dispatch = useDispatch();
  // const image = useSelector((state) => state.image.image);

  const onImageUpload = (images, insertImage) => {
    /* FileList does not support ordinary array methods */
    for (var i = 0; i < images.length; i++) {
      /* Stores as bas64enc string in the text.
       * Should potentially be stored separately and include just the url
       */
      // dispatch(uploadImage(images[i]));
      const reader = new FileReader();
      setTimeout(() => {
        reader.onloadend = () => {
          // insertImage(image);
        };
      }, 5000);
      reader.readAsDataURL(images[i]);
      console.log("file " + reader);
    }
  };
  useEffect(() => {});

  // const onChange = content => {
  //   setEditorState(content);
  //   //var edited= content;
  //  // console.log("from editor   " + content);
  // };

  //   const fileHandler = event => {
  //     let fileObj = event.target.files[0];
  //     //just pass the fileObj as parameter
  //     ExcelRenderer(fileObj, (err, resp) => {
  //       if (err) {
  //         console.log(err);
  //       } else {
  //         console.log(resp.rows[0]);

  //         setEditorState({
  //           cols: resp.cols,
  //           rows: resp.rows
  //         });
  //       }
  //     });
  //   };

  return (
    <div className="SummerNote">
      <ReactSummernote
        className="rich_text_global"
        id="summernote"
        options={{
          height: props.height,
          width: props.width,
          fontSize: "100px",
          //dialogsInBody: true,

          toolbar: [
            ["style", ["style"]],
            ["color", ["color"]],
            ["font", ["bold", "underline", "clear"]],
            ["font", ["strikethrough", "superscript", "subscript"]],
            ["para", ["ul", "ol", "paragraph"]],
            ["insert", ["picture"]],
            // ["remove", ["removeMedia"]],
            ["view", ["codeview", "help"]],
            ["height", ["height"]],
          ],
          codeview: true,
          prettifyHtml: true,
          iframe: true,
          airMode: false,
          styleWithSpan: true,
          direction: "ltr",
          // tabsize: 4, // size of tab ex) 2 or 4

          disableResizeEditor: false,
          focus: true,
          codemirror: {
            mode: "htmlmixed",
            lineWrapping: true,
            lineNumbers: "true",

            theme: "monokai",
          },
        }}
        children={props.data}
        onChange={props.onChange}
        onImageUpload={onImageUpload}
        name={props.name}
        onFocus={props.onFocus}
      ></ReactSummernote>
      <div>
        {/* <input type="file" onChange={fileHandler} style={{ padding: "10px" }} /> */}
      </div>

      {/* <Mathjx math1={props.data} /> */}
      {/* <div>{EditorState}</div> */}
    </div>
  );
};

export default AdvancedSummernote;
