import axios from "axios";
import { baseUrl } from "../../config/baseUrl";
import { setAlert, removeAlert } from "../actions/Alert";
import { SET_ALERT, REMOVE_ALERT } from "../actions/Alert";
export const GET_BOARDS_BY_CONTENT_PROVIDER_REQUEST =
  "GET_BOARDS_BY_CONTENT_PROVIDER_REQUEST";
export const GET_BOARDS_BY_CONTENT_PROVIDER_FAILURE =
  "GET_BOARDS_BY_CONTENT_PROVIDER_FAILURE";
export const GET_BOARDS_BY_CONTENT_PROVIDER_SUCCESS =
  "GET_BOARDS_BY_CONTENT_PROVIDER_SUCCESS";

export const getBoardsByContentProvider = (contentProviderId) => async (
  dispatch
) => {
  const token = localStorage.getItem("token");
  dispatch({
    type: GET_BOARDS_BY_CONTENT_PROVIDER_REQUEST,
  });
  if (token) {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const res = await axios.get(
        `${baseUrl}/api/board/content-provider/${contentProviderId}`,
        config
      );
      console.log("boards  " + JSON.stringify(res.data));
      if (res.status === 200) {
        dispatch({
          type: GET_BOARDS_BY_CONTENT_PROVIDER_SUCCESS,
          payload: res.data.boards,
        });
      } else {
        dispatch({
          type: GET_BOARDS_BY_CONTENT_PROVIDER_FAILURE,
          payload: res.data.error,
        });
      }
    } catch (err) {
      dispatch({
        type: GET_BOARDS_BY_CONTENT_PROVIDER_FAILURE,
        payload: err,
      });
    }
  }
};
