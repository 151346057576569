import React, { useEffect, useState } from "react";
import Drawer from "../components/Drawer";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip, Fab, Typography, Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import CustomSelect from "../components/CustomSelect";
import CustomUnitSelect from "../components/CustomUnitSelect";
import Alert from "../components/Alert";
import Theme from "../Theme";
import XLSX from "xlsx";
import { getContentProviders } from "../store/actions/ContentProvider";
import { getBoardsByContentProvider } from "../store/actions/Board";
import { getStandardsByBoard } from "../store/actions/Standard";
import { getCoursesByStandard } from "../store/actions/Course";
import { getAllSubjects, getSubjectsByCourse } from "../store/actions/Subject";
import { getUnitsBySubject } from "../store/actions/Unit";

import { uploadQuestionsFile } from "../store/actions/Question";
import { getAccountDetail } from "../store/actions/Account";
import { getUsers, createUser } from "../store/actions/User";

const QuestionFileUpload = (props) => {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users.users);
  const contentProviders = useSelector(
    (state) => state.contentProviders.contentProviders
  );
  const boards = useSelector((state) => state.boards.boards);
  const standards = useSelector((state) => state.standards.standards);
  const courses = useSelector((state) => state.courses.courses);
  const subjects = useSelector((state) => state.subjects.subjects);
  const units = useSelector((state) => state.units.units);
  const user = useSelector((state) => state.auth.user);
  const account = useSelector((state) => state.account.account);

  const [contentProviderId, setContentProviderId] = useState("");
  const [boardId, setBoardId] = useState("");
  const [standardId, setStandardId] = useState("");
  const [courseId, setCourseId] = useState("");
  const [subjectId, setSubjectId] = useState("");
  const [unitId, setUnitId] = useState("");
  const [createdBy, setCretedBy] = useState("");
  const alert = useSelector((state) => state.alert.alert);
  const [questions, setQuestions] = useState([]);

  const [contentProviderError, setContentProviderError] = useState("");
  const [boardError, setBoardError] = useState("");
  const [standardError, setStandardError] = useState("");
  const [courseError, setCourseError] = useState("");
  const [subjectError, setSubjectError] = useState("");
  const [unitError, setUnitError] = useState("");
  const [createdByError, setCreatedByError] = useState("");
  const [choiceError, setChoiceError] = useState("");

  useEffect(() => {
    // dispatch(getQuestions(user.id));
    //dispatch(getAccountDetail(user.id));
    dispatch(getAllSubjects());
    dispatch(getContentProviders());
    dispatch(getUsers());
  }, [dispatch]);

  const onContentProviderChange = (e) => {
    e.preventDefault();
    setContentProviderId(e.target.value);
    dispatch(getBoardsByContentProvider(e.target.value));
    setContentProviderError("");
  };

  const onBoardChange = (e) => {
    e.preventDefault();
    setBoardId(e.target.value);
    dispatch(getStandardsByBoard(e.target.value));
    setBoardError("");
  };
  const onStandardChange = (e) => {
    e.preventDefault();
    setStandardId(e.target.value);
    dispatch(getCoursesByStandard(e.target.value));
    setStandardError("");
  };

  const onCourseChange = (e) => {
    e.preventDefault();
    setCourseId(e.target.value);
    dispatch(getSubjectsByCourse(e.target.value));
    setCourseError("");
  };
  const onSubjectChange = (e) => {
    e.preventDefault();
    setSubjectId(e.target.value);
    dispatch(getUnitsBySubject(e.target.value));
    setSubjectError("");
  };
  const onUnitChange = (e) => {
    e.preventDefault();
    setUnitId(e.target.value);
    setUnitError("");
  };
  const onUserChange = (e) => {
    e.preventDefault();
    setCretedBy(e.target.value);

    setCreatedByError("");
  };

  const handleFileUpload = (e) => {
    e.preventDefault();

    var files = e.target.files,
      f = files[0];
    var reader = new FileReader();
    reader.onload = function (e) {
      var data = e.target.result;
      let readedData = XLSX.read(data, { type: "binary" });
      const wsname = readedData.SheetNames[0];
      const ws = readedData.Sheets[wsname];

      /* Convert array to json*/
      const dataParse = XLSX.utils.sheet_to_json(ws);

      let newData = dataParse.map((rec) => {

        let obj = {
          questionaire: {
            question: rec.question,
            solution: rec.solution,
            choices: [
              {
                label: "A",
                value: rec.choiceA,
                isCorrect: rec.isCorrectA == "YES" ? true : false,
                isSwappable: true,
              },
              {
                label: "B",
                value: rec.choiceB,
                isCorrect: rec.isCorrectB == "YES" ? true : false,
                isSwappable: true,
              },
              {
                label: "C",
                value: rec.choiceC,
                isCorrect: rec.isCorrectC == "YES" ? true : false,
                isSwappable: true,
              },
              {
                label: "D",
                value: rec.choiceD,
                isCorrect: rec.isCorrectD == "YES" ? true : false,
                isSwappable: true,
              },
            ],
          },
          hint: rec.hint,
          isHintAvailable: rec.hint == "NO" ? false : true,
          concept: rec.concept,
          difficultyLevel: rec.difficultyLevel ? rec.difficultyLevel : "Easy",
          objectiveType: rec.objectiveType ? rec.objectiveType : "Knowledge",
        };
        return obj;
      });
      //console.log(newData);
      // console.log(dataParse);
      //console.log(JSON.stringify(newData));
      setQuestions(newData);


setChoiceError(newData.map(qn=>qn.questionaire.choices.map(ch=>ch.isCorrect==true)).length+"/"+newData.length)
  console.log(newData.map(qn=>qn.questionaire.choices.map(ch=>ch.isCorrect==false)).length);

};
    reader.readAsBinaryString(f);
  };
  const validate = () => {
    if (contentProviderId === "") {
      setContentProviderError("Select Content Provider");
      return false;
    }
    if (boardId === "") {
      setBoardError("Select Board");
      return false;
    }
    if (standardId === "") {
      setStandardError("Select Standard");
      return false;
    }
    if (courseId === "") {
      setCourseError("Select Course");
      return false;
    }

    if (subjectId === "") {
      setSubjectError("Select Subject");
      return false;
    }
    if (unitId === "") {
      setUnitError("Select Unit");
      return false;
    }
    if (createdBy == "") {
      setCreatedByError("Select User");
      return false;
    }
    return true;
  };
  const onClickUploadFile = (e) => {
    const isValid = validate();

    if (isValid) {
      // const board = boardId;
      // const standard = standardId;
      // const course = courseId;
      // const subject = subjectId;
      // const unit = unitId;
      // const qData = {
      //   contentProvider,
      //   board,
      //   standard,
      //   course,
      //   subject,
      //   unit,
      //   questionSet: questions,
      // };
      // console.log(qData);
      console.log(questions);
      dispatch(
        uploadQuestionsFile({
          userId: createdBy,
          questionType: "single correct",
          contentProviderId,
          boardId,
          standardId,
          courseId,
          subjectId,
          unitId,
          questionSet: questions,
        })
      );
      setUnitId("");
      setSubjectId("");
      setCourseId("");
      setQuestions([]);
    }
  };
  return (
    <div>
      <Drawer
        headerName="Upload Question File"
        user={account !== null && account.name}
      >
        <div
          style={{
            //width: 800,
            alignItems: "center",
            //justifyContent: "center",
            //alignSelf: "center",
            //alignContent: "center",
            //flex: 1,
            // display: "flex",
            flexDirection: "column",
          }}
        >
          {alert && (
            <Alert severity={alert.alertType} message={alert.message} />
          )}
          <div>
            <div
              style={{
                marginLeft: 16,
                marginTop: 24,
                backgroundColor: "silver",
                padding: 8,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography style={{ marginRight: 16 }}>
                Upload Questions File
              </Typography>
            </div>
            <div
              style={{
                alignItems: "flex-start",
                justifyContent: "flex-start",
                // alignSelf: "center",
                display: "flex",
                flexDirection: "column",
                marginLeft: 8,
              }}
            >
              <CustomSelect
                required={true}
                label="Content Provider"
                value={contentProviderId}
                options={contentProviders}
                error={contentProviderError}
                firstMenu="Select Content Provider"
                onChange={onContentProviderChange}
                minWidth={300}
              />
              <CustomSelect
                required={true}
                label="Boards"
                value={boardId}
                options={boards}
                error={boardError}
                firstMenu="Select Board"
                onChange={onBoardChange}
                minWidth={300}
              />
              <CustomSelect
                required={true}
                label="Standards"
                value={standardId}
                options={standards}
                error={standardError}
                firstMenu="Select Standard"
                onChange={onStandardChange}
                minWidth={300}
              />
              <CustomSelect
                required={true}
                label="Courses"
                value={courseId}
                options={courses}
                error={courseError}
                firstMenu="Select Course"
                onChange={onCourseChange}
                minWidth={300}
              />
              <CustomSelect
                required={true}
                label="Subjects"
                value={subjectId}
                options={subjects}
                error={subjectError}
                firstMenu="Select Subject"
                onChange={onSubjectChange}
                minWidth={300}
              />
              <CustomUnitSelect
                required={true}
                label="Units"
                options={units}
                value={unitId}
                error={unitError}
                onChange={onUnitChange}
                firstMenu="Select Unit"
                minWidth={300}
              />
              <CustomSelect
                required={true}
                label="Created By"
                value={createdBy}
                options={users}
                error={createdByError}
                firstMenu="Select User"
                onChange={onUserChange}
                minWidth={300}
              />
              <div
                style={{
                  margin: 8,
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <input
                  type="file"
                  required
                  accept=".xlsx"
                  onChange={handleFileUpload}
                />
                <Typography variant="body1">
                  {questions.length > 0 && questions.length}{" "}
                  {questions.length > 0 && "Questions"}{" "}
                </Typography>
                <Typography variant="body1">
                   total Choices =
                 {choiceError}
                </Typography>
              </div>
              <div
                style={{
                  margin: 8,
                }}
              >
                <Button
                  size="medium"
                  variant="contained"
                  color="primary"
                  disableElevation
                  onClick={onClickUploadFile}
                >
                  Upload
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default QuestionFileUpload;
