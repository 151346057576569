import axios from "axios";
import { baseUrl } from "../../config/baseUrl";
import { setAlert, removeAlert } from "../actions/Alert";
import { SET_ALERT, REMOVE_ALERT } from "../actions/Alert";
export const GET_SUBUNITS_BY_UNIT_REQUEST = "GET_SUBUNITS_BY_UNIT_REQUEST";
export const GET_SUBUNITS_BY_UNIT_FAILURE = "GET_SUBUNITS_BY_UNIT_FAILURE";
export const GET_SUBUNITS_BY_UNIT_SUCCESS = "GET_SUBUNITS_BY_UNIT_SUCCESS";
export const CREATE_SUBUNITS_REQUEST = "CREATE_SUBUNITS_REQUEST";
export const CREATE_SUBUNITS_FAILURE = "CREATE_SUBUNITS_FAILURE";
export const CREATE_SUBUNITS_SUCCESS = "CREATE_SUBUNITS_SUCCESS";

export const getSubunitsByUnit = (unitId) => async (dispatch) => {
  const token = localStorage.getItem("token");
  dispatch({
    type: GET_SUBUNITS_BY_UNIT_REQUEST,
  });
  if (token) {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const res = await axios.get(
        `${baseUrl}/api/subunit/unit/${unitId}`,
        config
      );

      if (res.status === 200) {
        dispatch({
          type: GET_SUBUNITS_BY_UNIT_SUCCESS,
          payload: res.data.subunits,
        });
      } else {
        dispatch({
          type: GET_SUBUNITS_BY_UNIT_FAILURE,
          payload: res.data.error,
        });
      }
    } catch (err) {
      dispatch({
        type: GET_SUBUNITS_BY_UNIT_FAILURE,
        payload: err,
      });
    }
  }
};

export const createSubunits = ({ unitId, subunits }) => async (dispatch) => {
  const token = localStorage.getItem("token");
  dispatch({
    type: CREATE_SUBUNITS_REQUEST,
  });
  if (token) {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const body = JSON.stringify({ unitId, subunits });
    try {
      const res = await axios.post(
        `${baseUrl}/api/subunit/multiple-upload`,
        body,
        config
      );

      if (res.status === 200) {
        dispatch({
          type: CREATE_SUBUNITS_SUCCESS,
          payload: res.data.concepts,
        });
        dispatch({
          type: SET_ALERT,
          payload: { message: res.data.message, alertType: "success" },
        });
        setTimeout(() => {
          dispatch({
            type: REMOVE_ALERT,
          });
        }, 5000);
      } else {
        dispatch({
          type: SET_ALERT,
          payload: { message: res.data.error, alertType: "error" },
        });
        setTimeout(() => {
          dispatch({
            type: REMOVE_ALERT,
          });
        }, 5000);
        dispatch({
          type: CREATE_SUBUNITS_FAILURE,
          payload: res.data.error,
        });
      }
    } catch (err) {
      dispatch({
        type: SET_ALERT,
        payload: { message: err, alertType: "error" },
      });
      setTimeout(() => {
        dispatch({
          type: REMOVE_ALERT,
        });
      }, 5000);
      dispatch({
        type: CREATE_SUBUNITS_FAILURE,
        payload: err,
      });
    }
  }
};
