import React from "react";
import { Typography } from "@material-ui/core";
const Header = (props) => {
  return (
    <div
      style={{
        backgroundColor: "#eeeeee",
        paddingTop: 8,
        paddingBottom: 8,
        minHeight: 40,
        // marginBottom: 8,
        paddingLeft: 8,
        alignContent: "center",
        alignItems: "center",
        width: "100%",
        display: "flex",
      }}
    >
      <div>
        <div
          style={{
            // display: "flex",
            justifyContent: "space-between",
            alignContent: "center",
            //alignItems: "center",
            marginRight: 16,
          }}
        >
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Typography style={{ marginRight: 16 }}>
              {" "}
              {props.standard}th Standard -
            </Typography>
            <Typography style={{ marginRight: 16 }}>
              {" "}
              {props.subjectName}{" "}-
            </Typography>
            <Typography style={{ marginRight: 16 }}>
              {" "}
              {props.unitName}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
