import axios from "axios";
import { baseUrl } from "../../config/baseUrl";
import { setAlert, removeAlert } from "../actions/Alert";
import { SET_ALERT, REMOVE_ALERT } from "../actions/Alert";
export const UPLOAD_QUESTIONS_FILE_REQUEST = "UPLOAD_QUESTIONS_FILE_REQUEST";
export const UPLOAD_QUESTIONS_FILE_FAILURE = "UPLOAD_QUESTIONS_FILE_FAILURE";
export const UPLOAD_QUESTIONS_FILE_SUCCESS = "UPLOAD_QUESTIONS_FILE_SUCCESS";

export const uploadQuestionsFile = ({
  userId,
  questionType,
  contentProviderId,
  boardId,
  standardId,
  courseId,
  subjectId,
  unitId,
  questionSet,
}) => async (dispatch) => {
  const token = localStorage.getItem("token");
  dispatch({
    type: UPLOAD_QUESTIONS_FILE_REQUEST,
  });
  if (token) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    const body = JSON.stringify({
      userId,
      questionType,
      contentProviderId,
      boardId,
      standardId,
      courseId,
      subjectId,
      unitId,
      questionSet,
    });

    try {
      const res = await axios.post(
        `${baseUrl}/api/question/multiple-upload`,
        body,
        config
      );
      console.log(JSON.stringify(res));
      if (res.status === 200) {
        dispatch({
          type: UPLOAD_QUESTIONS_FILE_SUCCESS,
          payload: res.data.message,
        });
        dispatch({
          type: SET_ALERT,
          payload: { message: res.data.message, alertType: "success" },
        });
        setTimeout(() => {
          dispatch({
            type: REMOVE_ALERT,
          });
        }, 5000);
      } else {
        dispatch({
          type: SET_ALERT,
          payload: { message: res.data.error, alertType: "error" },
        });
        setTimeout(() => {
          dispatch({
            type: REMOVE_ALERT,
          });
        }, 5000);
        dispatch({
          type: UPLOAD_QUESTIONS_FILE_FAILURE,
          payload: res.data.error,
        });
      }
    } catch (err) {
      dispatch({
        type: SET_ALERT,
        payload: { message: err, alertType: "error" },
      });
      setTimeout(() => {
        dispatch({
          type: REMOVE_ALERT,
        });
      }, 5000);
      dispatch({
        type: UPLOAD_QUESTIONS_FILE_FAILURE,
        payload: err,
      });
    }
  }
};
