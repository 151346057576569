import React, { useEffect, useState } from "react";
import Drawer from "../components/Drawer";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip, Fab, Typography, Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import CustomSelect from "../components/CustomSelect";
import CustomUnitSelect from "../components/CustomUnitSelect";
import Theme from "../Theme";
import XLSX from "xlsx";
import {
  getQuestions,
  getQuestionsByUnitId,
  getSubjects,
  getUnits,
  deleteQuestion,
  assignUnit,
} from "../store/actions/Content";
import { getAccountDetail } from "../store/actions/Account";
import { getAllSubjects } from "../store/actions/Subject";
import { getUnitsBySubject } from "../store/actions/Unit";
import Mathjax from "../components/Mathjax";
import MaterialTable from "material-table";

const Dashboard = (props) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const account = useSelector((state) => state.account.account);
  const subjects = useSelector((state) => state.subjects.subjects);
  const units = useSelector((state) => state.units.units);
  const questions = useSelector((state) => state.content.questions);

  const [subject, setSubject] = useState(
    account !== null ? account.subject._id : ""
  );
  const [unit, setUnit] = useState(
    account !== null ? account.unit._id : ""
  );
  // console.log(JSON.stringify(questions));
  const col = [
    {
      field: "questionNumber",
      title: "Question #",

      width: 150,
    },
    {
      field: "isPending",
      title: "Is Pending?",
      render: (rowData) => <div>{rowData.isPending ? "YES" : "NO"}</div>,
      width: 50,
    },
    {
      field: "questionStatus",
      title: "Is Verified?",
      render: (rowData) => (
        <div>{rowData.questionStatus > 1 ? "YES" : "NO"}</div>
      ),
      width: 50,
    },
    {
      field: "pendingItem",
      title: "Issue",
      width: 50,
    },

    {
      field: "questionaire.question",
      title: "Question",
      render: (rowData) => <Mathjax math={rowData.questionaire.question} />,
      width: 150,
    },
    { field: "subunit.number", title: "Subunit # ", width: 150 },
    { field: "subunit.name", title: "Subunit Name ", width: 150 },
    { field: "concept.number", title: "Concept # ", width: 150 },
    { field: "concept.name", title: "Concept Name ", width: 150 },
    { field: "createdBy.name", title: "Created By ", width: 150 },
    { field: "updatedBy.name", title: "Updated By ", width: 150 },
  ];
  const row = { display: "flex", flexDirection: "row", alignContent: "center" };

  useEffect(() => {
    dispatch(getAccountDetail(user.id));
    //dispatch(getAllSubjects());
    if(account){
      dispatch(getUnitsBySubject(account.subject._id));
    dispatch(getQuestionsByUnitId(account.unit._id));
    }
  }, [dispatch]);

  const onClickQuestion = (rowData) => {
    //console.log(rowData);
    props.history.push({
      pathname: "/update-question",
      data: rowData,
    });
  };

  const onClickDeleteQuestion = (e) => {
    dispatch(deleteQuestion(e));
  };
  const onSubjectChange = (e) => {
    e.preventDefault();
    setSubject(e.target.value);
    dispatch(getUnits(e.target.value));
  };
  const onClickViewPdf = () => {
    props.history.push("/questions-pdf");
  };

  const onUnitChange = (e) => {
    e.preventDefault();
    setUnit(e.target.value);
    dispatch(getQuestionsByUnitId(e.target.value));
    dispatch(
      assignUnit({
        unitId: e.target.value,
        subjectId: subject,
        userId: user.id,
      })
    );
  };
  return (
    <div>
      <Drawer headerName="View Questions">
        <div
          style={{
            width: 1560,
            alignItems: "center",
            //justifyContent: "center",
            //alignSelf: "center",
            //alignContent: "center",
            //flex: 1,
            // display: "flex",
            flexDirection: "column",
          }}
        >
          <div style={{ ...row }}>
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flex: 1,
                  flexGrow: 1,
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flex: 1,
                    flexGrow: 1,
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      backgroundColor: "silver",
                      paddingLeft: 16,
                      paddingRight: 16,
                      marginRight: 8,
                      borderRadius: 4,
                      height: 46,
                    }}
                  >
                    <Typography>
                      {account !== null
                        ? account.standard.name + "th Standard"
                        : "No Standard is assigned"}
                    </Typography>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      backgroundColor: "silver",
                      paddingLeft: 16,
                      paddingRight: 16,
                      marginRight: 8,
                      borderRadius: 4,
                      height: 46,
                    }}
                  >
                    <Typography>
                      {account !== null
                        ? account.subject.name
                        : "No Subject is assigned"}
                    </Typography>
                  </div>
                
                  {
                   user.role == "ADMIN"?   
                   <CustomUnitSelect
                    required={true}
                    label="Unit"
                    options={units}
                    value={unit}
                    error=""
                    onChange={onUnitChange}
                    firstMenu="Select Unit"
                    minWidth={400}
                  /> 
                  :
                 <div
                 style={{
                   display: "flex",
                   alignItems: "center",
                   backgroundColor: "silver",
                   paddingLeft: 16,
                   paddingRight: 16,
                   marginRight: 8,
                   borderRadius: 4,
                   height: 46,
                 }}
               >
                 <Typography>
                   {account !== null
                     ? account.unit.name
                     : "No Unit is assigned"}
                 </Typography>
               </div>
                  }
                  {/* <CustomUnitSelect
                    required={true}
                    label="Unit"
                    options={units}
                    value={unit}
                    error=""
                    onChange={onUnitChange}
                    firstMenu="Select Unit"
                    minWidth={400}
                  /> */}
                 
                  {user.role == "ADMIN" && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={onClickViewPdf}
                    >
                      View as PDF
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div style={{ maxWidth: "100%" }}>
            <MaterialTable
              theme={Theme}
              columns={col}
              data={questions}
              title=""
              //onRowClick={(event, rowData) => onClickQuestion(rowData)}
              actions={[
                {
                  icon: "edit",
                  tooltip: "Edit Question",
                  onClick: (event, rowData) => onClickQuestion(rowData),
                },
              ]}
              options={{
                actionsColumnIndex: -1,
                //exportButton: true,
                // selection: true,
              }}
              editable={{
                onRowDelete: (oldData) =>
                  new Promise((resolve, reject) => {
                    setTimeout(() => {
                      dispatch(deleteQuestion(oldData._id));
                      resolve();
                    }, 1000);
                  }),
              }}
            />
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            bottom: 0,
            right: 0,
            marginBottom: 16,
            marginRight: 16,
            position: "fixed",
          }}
        >
          <Tooltip title="Add Question" aria-label="add">
            <Fab
              color="primary"
              aria-label="add"
              onClick={() => {
                props.history.push("/create-question");
              }}
            >
              <AddIcon style={{ color: "white" }} />
            </Fab>
          </Tooltip>
        </div>
      </Drawer>
    </div>
  );
};

export default Dashboard;
