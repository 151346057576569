import axios from "axios";
import { baseUrl } from "../../config/baseUrl";
import { setAlert, removeAlert } from "../actions/Alert";
import { SET_ALERT, REMOVE_ALERT } from "../actions/Alert";

export const GET_SUBJECTS_BY_COURSE_REQUEST = "GET_SUBJECTS_BY_COURSE_REQUEST";
export const GET_SUBJECTS_BY_COURSE_FAILURE = "GET_SUBJECTS_BY_COURSE_FAILURE";
export const GET_SUBJECTS_BY_COURSE_SUCCESS = "GET_SUBJECTS_BY_COURSE_SUCCESS";

export const GET_ALL_SUBJECTS_REQUEST = "GET_ALL_SUBJECTS_REQUEST";
export const GET_ALL_SUBJECTS_FAILURE = "GET_ALL_SUBJECTS_FAILURE";
export const GET_ALL_SUBJECTS_SUCCESS = "GET_ALL_SUBJECTS_SUCCESS";

export const UPDATE_SUBJECT_REQUEST = "UPDATE_SUBJECT_REQUEST";
export const UPDATE_SUBJECT_FAILURE = "UPDATE_SUBJECT_FAILURE";
export const UPDATE_SUBJECT_SUCCESS = "UPDATE_SUBJECT_SUCCESS";

export const getSubjectsByCourse = (courseId) => async (dispatch) => {
  const token = localStorage.getItem("token");
  dispatch({
    type: GET_SUBJECTS_BY_COURSE_REQUEST,
  });
  if (token) {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const res = await axios.get(
        `${baseUrl}/api/subject/course/${courseId}`,
        config
      );

      if (res.status === 200) {
        dispatch({
          type: GET_SUBJECTS_BY_COURSE_SUCCESS,
          payload: res.data.subjects,
        });
      } else {
        dispatch({
          type: GET_SUBJECTS_BY_COURSE_FAILURE,
          payload: res.data.error,
        });
      }
    } catch (err) {
      dispatch({
        type: GET_SUBJECTS_BY_COURSE_FAILURE,
        payload: err,
      });
    }
  }
};

export const getAllSubjects = () => async (dispatch) => {
  const token = localStorage.getItem("token");
  dispatch({
    type: GET_ALL_SUBJECTS_REQUEST,
  });
  if (token) {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const res = await axios.get(`${baseUrl}/api/subject`, config);

      if (res.status === 200) {
        dispatch({
          type: GET_ALL_SUBJECTS_SUCCESS,
          payload: res.data.subjects,
        });
      } else {
        dispatch({
          type: GET_ALL_SUBJECTS_FAILURE,
          payload: res.data.error,
        });
      }
    } catch (err) {
      dispatch({
        type: GET_ALL_SUBJECTS_FAILURE,
        payload: err,
      });
    }
  }
};

export const uploadSubjectImage = ({ subjectId, imageUrl }) => async (
  dispatch
) => {
  const token = localStorage.getItem("token");
  dispatch({
    type: UPDATE_SUBJECT_REQUEST,
  });
  if (token) {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const body = JSON.stringify({
      subjectId,
      imageUrl,
    });
    console.log(body);

    try {
      const res = await axios.post(
        `${baseUrl}/api/subject/upload/image`,
        body,
        config
      );
      console.log(res.data);
      if (res.status === 200) {
        dispatch({
          type: UPDATE_SUBJECT_SUCCESS,
          payload: res.data.message,
        });
      } else {
        dispatch({
          type: UPDATE_SUBJECT_FAILURE,
          payload: res.data.error,
        });
      }
    } catch (err) {
      dispatch({
        type: UPDATE_SUBJECT_FAILURE,
        payload: "Request Failed",
      });
    }
  }
};
