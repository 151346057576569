import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import {
  Button,
  CssBaseline,
  TextField,
  Link,
  Box,
  Typography,
  makeStyles,
  Container,
  Card,
} from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";

import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../store/actions/Auth";
function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="/">
        impru.com
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // backgroundColor: "yellow",
    // paddingBottom: 80,
    paddingTop: 80,
    padding: 64,
    // display: "flex",
    //flexDirection: "row",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const SignIn = (props) => {
  const classes = useStyles();

  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  if (auth.isAuthenticated) {
    //return <Redirect to="/admin/dashboard" />;
    return <Redirect to="/" />;
  }
  const login = () => {
    if (email === "" || password === "") {
      setEmailError(true);
      setPasswordError(true);
    } else {
      dispatch(loginUser({ email, password }));
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Card style={{ borderRadius: 0 }} className={classes.paper}>
        <div style={{ minWidth: 300 }}>
          {auth.message && (
            <Alert severity="success">
              <AlertTitle>SignIn Success</AlertTitle>
              {auth.message}
            </Alert>
          )}
          {auth.error && (
            <Alert severity="error">
              <AlertTitle>SignIn Failure</AlertTitle>
              {auth.error}
            </Alert>
          )}
        </div>
        {/* <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar> */}
        <Typography variant="h4">Sign In</Typography>
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            error={emailError}
            margin="normal"
            required
            fullWidth
            label="email ID"
            name="email"
            autoFocus
            value={email}
            onChange={(e) => {
              setemail(e.target.value);
            }}
          />
          <TextField
            variant="outlined"
            error={passwordError}
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => {
              setpassword(e.target.value);
            }}
          />

          <Button
            // type="submit" // default submit action triggers
            fullWidth
            variant="contained"
            color="primary"
            size="large"
            className={classes.submit}
            onClick={login}
          >
            Sign In
          </Button>
          {/* <div>
            <div style={{ textAlign: "center" }}>
              <Link href="/forgot-password" variant="body2">
                Forgot password?
              </Link>
            </div>
            <div style={{ textAlign: "center" }}>
              <Link href="/verify-account" variant="body2">
                {"Have an account ? Please verify"}
              </Link>
            </div>
          </div> */}
        </form>
      </Card>

      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
};

export default SignIn;
