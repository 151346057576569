import axios from "axios";
import { baseUrl } from "../../config/baseUrl";
import { setAlert, removeAlert } from "../actions/Alert";
import { SET_ALERT, REMOVE_ALERT } from "../actions/Alert";
export const GET_CONTENT_PROVIDERS_REQUEST = "GET_CONTENT_PROVIDERS_REQUEST";
export const GET_CONTENT_PROVIDERS_FAILURE = "GET_CONTENT_PROVIDERS_FAILURE";
export const GET_CONTENT_PROVIDERS_SUCCESS = "GET_CONTENT_PROVIDERS_SUCCESS";

export const getContentProviders = () => async (dispatch) => {
  const token = localStorage.getItem("token");
  dispatch({
    type: GET_CONTENT_PROVIDERS_REQUEST,
  });
  if (token) {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const res = await axios.get(`${baseUrl}/api/content-provider`, config);

      if (res.status === 200) {
        dispatch({
          type: GET_CONTENT_PROVIDERS_SUCCESS,
          payload: res.data.contentProviders,
        });
      } else {
        dispatch({
          type: GET_CONTENT_PROVIDERS_FAILURE,
          payload: res.data.error,
        });
      }
    } catch (err) {
      dispatch({
        type: GET_CONTENT_PROVIDERS_FAILURE,
        payload: err,
      });
    }
  }
};
