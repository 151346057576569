import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import MaterialTable from "material-table";
import Drawer from "../components/Drawer";
import CustomSelect from "../components/CustomSelect";
import { getAllSubjects } from "../store/actions/Subject";
import { getUnitsBySubject } from "../store/actions/Unit";
import { Button } from "@material-ui/core";
import { getContentProviders } from "../store/actions/ContentProvider";
import { getBoardsByContentProvider } from "../store/actions/Board";
import { getStandardsByBoard } from "../store/actions/Standard";
import { getCoursesByStandard } from "../store/actions/Course";
import { getSubjectsByCourse } from "../store/actions/Subject";
const Unit = (props) => {
  const contentProviders = useSelector(
    (state) => state.contentProviders.contentProviders
  );
  const boards = useSelector((state) => state.boards.boards);
  const standards = useSelector((state) => state.standards.standards);
  const courses = useSelector((state) => state.courses.courses);
  const subjects = useSelector((state) => state.subjects.subjects);

  const [contentProvider, setContentProvider] = useState("");
  const [board, setBoard] = useState("");
  const [standard, setStandard] = useState("");
  const [course, setCourse] = useState("");
  const [subject, setSubject] = useState("");

  const units = useSelector((state) => state.units.units);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getContentProviders());
  }, [dispatch]);

  const onContentProviderChange = (e) => {
    e.preventDefault();
    setContentProvider(e.target.value);
    dispatch(getBoardsByContentProvider(e.target.value));
  };
  const onBoardChange = (e) => {
    e.preventDefault();
    setBoard(e.target.value);
    dispatch(getStandardsByBoard(e.target.value));
  };
  const onStandardChange = (e) => {
    e.preventDefault();
    setStandard(e.target.value);
    dispatch(getCoursesByStandard(e.target.value));
  };
  const onCourseChange = (e) => {
    e.preventDefault();
    setCourse(e.target.value);
    dispatch(getSubjectsByCourse(e.target.value));
  };
  const onSubjectChange = (e) => {
    console.log("subject ID" + e.target.value);
    setSubject(e.target.value);
    dispatch(getUnitsBySubject(e.target.value));
  };
  const onClickAddUnits = () => {
    props.history.push({
      pathname: "/add-units",
    });
  };
  const onClickToEditUnit = (rowData) => {
    //console.log(rowData);
    props.history.push({
      pathname: "/update-unit",
      data: rowData,
    });
  };
  return (
    <div>
      <Drawer headerName="Units">
        <div style={{ maxWidth: "100%" }}>
          <div className="container">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flex: 1,
                flexGrow: 1,
                justifyContent: "space-between",
              }}
            >
              <CustomSelect
                label="Content Provider"
                value={contentProvider}
                options={contentProviders}
                firstMenu="Select Content Providre"
                onChange={onContentProviderChange}
              />
              <CustomSelect
                label="Board"
                value={board}
                options={boards}
                firstMenu="Select Board"
                onChange={onBoardChange}
              />
              <CustomSelect
                label="Standard"
                value={standard}
                options={standards}
                firstMenu="Select Standard"
                onChange={onStandardChange}
              />
              <CustomSelect
                label="Course"
                value={course}
                options={courses}
                firstMenu="Select Course"
                onChange={onCourseChange}
              />
              <CustomSelect
                label="Subjects"
                value={subject}
                options={subjects}
                firstMenu="Select Subject"
                onChange={onSubjectChange}
              />
              <div style={{ display: "flex", alignItems: "center" }}>
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  disableElevation
                  onClick={onClickAddUnits}
                >
                  Add Units
                </Button>
              </div>
            </div>
            <MaterialTable
              columns={[
                {
                  title: "Image",
                  field: "imageUrl",
                  render: (rowData) => (
                    <img
                      src={rowData.imageUrl}
                      style={{ width: 40, height: 40 }}
                    />
                  ),
                  export: false,
                },
                { title: "Unit Number", field: "number" },
                { title: "Unit Name", field: "name" },
                { title: "Description", field: "description" },
                { title: "Subject", field: "subject.name" },
                { title: "Is Published", field: "isPublished" },
              ]}
              data={units}
              title="Units"
              options={{
                exportButton: true,
              }}
              actions={[
                {
                  icon: "edit",
                  tooltip: "Update Unit",
                  onClick: (event, rowData) => onClickToEditUnit(rowData),
                },
              ]}
            />
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default Unit;
