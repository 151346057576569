//import isEmpty from "../../validation/is-empty";
import {
  GET_UNITS_BY_SUBJECT_FAILURE,
  GET_UNITS_BY_SUBJECT_REQUEST,
  GET_UNITS_BY_SUBJECT_SUCCESS,
  CREATE_UNITS_FAILURE,
  CREATE_UNITS_REQUEST,
  CREATE_UNITS_SUCCESS,
} from "../actions/Unit";

const initialState = {
  units: [],
  message: null,
  error: null,
  isLoading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CREATE_UNITS_REQUEST:
    case GET_UNITS_BY_SUBJECT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case CREATE_UNITS_SUCCESS:
    case GET_UNITS_BY_SUBJECT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        units: action.payload,
      };
    case CREATE_UNITS_FAILURE:
    case GET_UNITS_BY_SUBJECT_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
