import React, { useEffect, useState } from "react";
import axios from "axios";
import { baseUrl } from "../config/baseUrl";
import Drawer from "../components/Drawer";
import CustomSelect from "../components/CustomSelect";
import CustomConceptSelect from "../components/CustomConceptSelect";
import CustomSubunitSelect from "../components/CustomSubunitSelect";
import Summernote from "../components/UpdateSummernote";
import { CopyToClipboard } from "react-copy-to-clipboard";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

//import XLSX from "xlsx";
import ToggleChoice from "../components/ToggleChoice";
import Alert from "../components/Alert";
import Header from "../components/Header";
import { useDispatch, useSelector } from "react-redux";
import { Button, Typography, Checkbox } from "@material-ui/core";
import Mathjax from "../components/Mathjax";
import {
  getConcepts,
  getSubunits,
  update,
  deleteQuestion,
} from "../store/actions/Content";
import { getAccountDetail } from "../store/actions/Account";
import { getSubunitsByUnit } from "../store/actions/Subunit";
import { getObjectiveTypesByBoard } from "../store/actions/ObjectiveType";
import { getDifficultyLevelsByBoard } from "../store/actions/DifficultyLevel";
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 400,
  },
}));

const UpdateQuestion = (props) => {
  const {
    _id,
    isHintAvailable,
    hints,
    standardId,
    subjectId,
    unitId,
    board,
    subunit,
    concept,
    examNames,
    examYears,
    questionaire,

    objectiveType,
    difficultyLevel,
    isPending,
    pendingItem,
    questionNumber,
  } = props.location.data;
  console.log("isPendingPendingItem" + pendingItem);
  const classes = useStyles();
  const dispatch = useDispatch();
  const errorsList = [
    "Diagram is missing",
    "Solution is missing",
    "Option is missing or wrong",
    "Question is incomplete",
  ];
  const alert = useSelector((state) => state.alert.alert);
  const subunits = useSelector((state) => state.content.subunits);
  const concepts = useSelector((state) => state.content.concepts);
  const objectiveTypes = useSelector(
    (state) => state.objectiveTypes.objectiveTypes
  );
  const difficultyLevels = useSelector(
    (state) => state.difficultyLevels.difficultyLevels
  );

  const account = useSelector((state) => state.account.account);
  const user = useSelector((state) => state.auth.user);
  const [subunitID, setSubunitID] = useState(subunit._id);
  const [conceptID, setConceptID] = useState(concept._id);
  const [question, setQuestion] = useState(questionaire.question);
  const [isCopied, setIsCopied] = useState(null);
  const [image, setImage] = useState("");

  const [preview, setPreview] = useState("");
  //const [isSolutionAvailable, setisSolutionAvailable] = useState(true);
  const [solution, setSolution] = useState(questionaire.solution);
  const [choiceA, setChoiceA] = useState(questionaire.choices[0].value);
  const [choiceB, setChoiceB] = useState(questionaire.choices[1].value);
  const [choiceC, setChoiceC] = useState(questionaire.choices[2].value);
  const [choiceD, setChoiceD] = useState(questionaire.choices[3].value);
  const [choiceACorrect, setChoiceACorrect] = useState(
    questionaire.choices[0].isCorrect
  );
  const [choiceBCorrect, setChoiceBCorrect] = useState(
    questionaire.choices[1].isCorrect
  );
  const [choiceCCorrect, setChoiceCCorrect] = useState(
    questionaire.choices[2].isCorrect
  );
  const [choiceDCorrect, setChoiceDCorrect] = useState(
    questionaire.choices[3].isCorrect
  );
  const [isPendingLocal, setIsPendingLocal] = useState(isPending);
  const [pendingItemLocal, setPendingItemLocal] = useState(pendingItem);
  const [isHintAvail, setisHintAvail] = useState(isHintAvailable);
  const [hinta, setHint] = useState(hints.length > 0 ? hints[0] : "");
  const [difficultyLevelCode, setDifficultyLevel] = useState(
    difficultyLevel._id
  );

  const [objectiveTypeCode, setObjectiveType] = useState(objectiveType._id);
  const [exam, setExam] = useState(examNames.length > 0 ? examNames[0] : "");
  const [year, setYear] = useState(examYears.length > 0 ? examYears[0] : "");

  const [questionError, setQuestionError] = useState("");
  const [solutionError, setSolutionError] = useState("");
  const [choiceAError, setChoiceAError] = useState("");
  const [choiceBError, setChoiceBError] = useState("");
  const [choiceCError, setChoiceCError] = useState("");
  const [choiceDError, setChoiceDError] = useState("");
  const [hintError, setHintError] = useState("");
  const [subunitError, setSubunitError] = useState("");
  const [conceptError, setConceptError] = useState("");
  const [difficultyLevelError, setDifficultyLevelError] = useState("");
  const [objectiveTypeError, setObjectiveTypeError] = useState("");
  const [emptyChoiceError, setEmptyChoiceError] = useState("");

  const label = {
    borderWidth: 3,
    borderColor: "red",
    marginRight: 8,
    borderRadius: 8,
    width: 40,
    height: 40,
    textAlign: "center",
    alignContent: "center",
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "center",
    display: "flex",
  };
  const container = {
    display: "flex",
    flexDirection: "row",
    alignContent: "center",
    // textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "center",
  };

  const row = { display: "flex", flexDirection: "row", alignContent: "center" };

  const examsList = [
    { _id: "CBSE", name: "CBSE" },
    { _id: "NMMS", name: "NMMS" },
    { _id: "NTSE", name: "NTSE" },
    { _id: "ICSE", name: "ICSE" },
  ];
  const years = [
    { _id: 2011, name: "2011" },
    { _id: 2012, name: "2012" },
    { _id: 2013, name: "2013" },
    { _id: 2014, name: "2014" },
    { _id: 2015, name: "2015" },
    { _id: 2016, name: "2016" },
    { _id: 2017, name: "2017" },
    { _id: 2018, name: "2018" },
    { _id: 2019, name: "2019" },
    { _id: 2020, name: "2020" },
  ];

  useEffect(() => {
    dispatch(getSubunits());
    //dispatch(getSubunitsByUnit(unit._id));
    dispatch(getAccountDetail(user.id));
    dispatch(getConcepts(subunit._id));
    dispatch(getDifficultyLevelsByBoard(board));
    dispatch(getObjectiveTypesByBoard(board));
  }, [dispatch]);

  if (alert !== null && alert.alertType == "success") {
    props.history.goBack();
  }
  const onSubunitChange = (e) => {
    e.preventDefault();
    setSubunitID(e.target.value);
    dispatch(getConcepts(e.target.value));
    setSubunitError("");
  };
  const onConceptChange = (e) => {
    e.preventDefault();
    setConceptID(e.target.value);
    setConceptError("");
  };
  const onDifficultyLevelChange = (e) => {
    e.preventDefault();
    setDifficultyLevel(e.target.value);
    setDifficultyLevelError("");
  };
  const onObjectiveTypeChange = (e) => {
    e.preventDefault();
    setObjectiveType(e.target.value);
    setObjectiveTypeError("");
  };
  const onExamChange = (e) => {
    e.preventDefault();
    setExam(e.target.value);
  };
  const onYearChange = (e) => {
    e.preventDefault();
    setYear(e.target.value);
  };

  const onQuestionChange = (e) => {
    //e.preventDefault();
    setQuestion(e);
    setPreview(e);
    setQuestionError("");
  };
  const onChoiceAChange = (e) => {
    // e.preventDefault();
    setChoiceA(e);
    setPreview(e);
    setChoiceAError("");
  };
  const onChoiceAToggle = (e) => {
    e.preventDefault();
    setChoiceACorrect(true);
    setChoiceBCorrect(false);
    setChoiceCCorrect(false);
    setChoiceDCorrect(false);
    setEmptyChoiceError("");
  };
  const onChoiceBChange = (e) => {
    //e.preventDefault();
    setChoiceB(e);
    setPreview(e);
    setChoiceBError("");
  };
  const onChoiceBToggle = (e) => {
    e.preventDefault();
    setChoiceBCorrect(true);
    setChoiceACorrect(false);
    setChoiceCCorrect(false);
    setChoiceDCorrect(false);
    setEmptyChoiceError("");
  };
  const onChoiceCChange = (e) => {
    //e.preventDefault();
    setChoiceC(e);
    setPreview(e);
    setChoiceCError("");
  };
  const onChoiceCToggle = (e) => {
    e.preventDefault();
    setChoiceCCorrect(true);
    setChoiceACorrect(false);
    setChoiceBCorrect(false);
    setChoiceDCorrect(false);
    setEmptyChoiceError("");
  };
  const onChoiceDChange = (e) => {
    // e.preventDefault();
    setChoiceD(e);
    setPreview(e);
    setChoiceDError("");
  };
  const onChoiceDToggle = (e) => {
    e.preventDefault();
    setChoiceDCorrect(true);
    setChoiceACorrect(false);
    setChoiceBCorrect(false);
    setChoiceCCorrect(false);
    setEmptyChoiceError("");
  };

  const onSolutionChange = (e) => {
    // e.preventDefault();
    setSolution(e);
    setPreview(e);
    setSolutionError("");
  };
  const onHintChange = (e) => {
    //e.preventDefault();
    setHint(e);
    setPreview(e);
    setHintError("");
  };
  const handlePendingItemChange = (event) => {
    setPendingItemLocal(event.target.value);
  };
  // validation

  const minValidate = () => {
    if (subunit === "") {
      setSubunitError("Subunit is required");
      return false;
    }
    if (concept === "") {
      setConceptError("Concept is required");
      return false;
    }
    if (difficultyLevel === "") {
      setDifficultyLevelError("Difficulty level is required");
      return false;
    }
    if (objectiveType === "") {
      setObjectiveTypeError("Objective  is required");
      return false;
    }
    return true;
  };

  const validate = () => {
    if (question === "") {
      setQuestionError("Question is required");
      return false;
    }
    if (choiceA === "") {
      setChoiceAError("Chice A is Empty");
      return false;
    }
    if (choiceB === "") {
      setChoiceBError("Chice B is Empty");
      return false;
    }
    if (choiceC === "") {
      setChoiceCError("Chice C is Empty");
      return false;
    }
    if (choiceD === "") {
      setChoiceDError("Chice D is Empty");
      return false;
    }
    if (solution === "") {
      setSolutionError("Solution is required");
      return false;
    }
    if (
      choiceACorrect === false &&
      choiceBCorrect === false &&
      choiceCCorrect === false &&
      choiceDCorrect === false
    ) {
      setEmptyChoiceError("Please select a choice");
      return false;
    }
    if (isHintAvail && hinta === "") {
      setHintError("Add hint or change hint status");
      return false;
    }
    if (subunitID === "") {
      setSubunitError("Subunit is required");
      return false;
    }
    if (conceptID === "") {
      setConceptError("Concept is required");
      return false;
    }
    if (difficultyLevelCode === "") {
      setDifficultyLevelError("Difficulty level is required");
      return false;
    }
    if (objectiveTypeCode === "") {
      setObjectiveTypeError("Objective  is required");
      return false;
    }

    return true;
  };
  const fileChangeHandler = async (event) => {
    //setSelectedFile(event.target.files[0]);
    // console.log(event.target.files[0]);
    const token = localStorage.getItem("token");
    if (token) {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: token,
          Accept: "*",
        },
      };

      const formData = new FormData();
      formData.append("imageFile", event.target.files[0]);
      formData.append("name", event.target.files[0].name);
      try {
        console.log("trying upload");

        const res = await axios.post(
          `${baseUrl}/api/image-file`,
          formData,
          config
        );
        setIsCopied(false);
        setImage(res.data.imageLocation);
      } catch (err) {
        console.log(err);
      }
    }
  };
  const onClickDeleteQuestion = () => {
    dispatch(deleteQuestion(_id));
  };
  const onCLickToUpdateQuestion = () => {
    if (pendingItemLocal !== 0) {
      const isMinValid = minValidate();
      if (isMinValid) {
        const questionData = {
          userId: user.id,
          _id: _id,
          questionaire: {
            question: question,
            choices: [
              {
                label: "A",
                value: choiceA,
                isCorrect: choiceACorrect,
                isSwappable: true,
              },
              {
                label: "B",
                value: choiceB,
                isCorrect: choiceBCorrect,
                isSwappable: true,
              },
              {
                label: "C",
                value: choiceC,
                isCorrect: choiceCCorrect,
                isSwappable: true,
              },
              {
                label: "D",
                value: choiceD,
                isCorrect: choiceDCorrect,
                isSwappable: true,
              },
            ],
            solution: solution,
            isSolutionAvailable: true,
          },
          hint: hinta,
          isHintAvailable: isHintAvail,
          examNames: exam == "" ? [] : [exam],
          examYears: year == "" ? [] : [year],
          difficultyLevel: difficultyLevelCode,
          objectiveType: objectiveTypeCode,
          subunitId: subunitID,
          conceptId: conceptID,
          isPending: pendingItemLocal !== 0 ? true : false,
          pendingItem: pendingItemLocal !== 0 && pendingItemLocal,
        };

        console.log("  question data" + JSON.stringify(questionData));
        dispatch(update(questionData));
      }
    } else {
      const isValid = validate();
      if (isValid) {
        const questionData = {
          userId: user.id,
          _id: _id,
          questionaire: {
            question: question,
            choices: [
              {
                label: "A",
                value: choiceA,
                isCorrect: choiceACorrect,
                isSwappable: true,
              },
              {
                label: "B",
                value: choiceB,
                isCorrect: choiceBCorrect,
                isSwappable: true,
              },
              {
                label: "C",
                value: choiceC,
                isCorrect: choiceCCorrect,
                isSwappable: true,
              },
              {
                label: "D",
                value: choiceD,
                isCorrect: choiceDCorrect,
                isSwappable: true,
              },
            ],
            solution: solution,
            isSolutionAvailable: true,
          },
          hint: hinta,
          isHintAvailable: isHintAvail,
          examNames: exam == "" ? [] : [exam],
          examYears: year == "" ? [] : [year],
          difficultyLevel: difficultyLevelCode,
          objectiveType: objectiveTypeCode,
          subunitId: subunitID,
          conceptId: conceptID,
          isPending: pendingItemLocal !== 0 ? true : false,
          pendingItem: pendingItemLocal !== 0 && pendingItemLocal,
        };

        console.log("  question data" + JSON.stringify(questionData));
        dispatch(update(questionData));
      }
      //
    }

    //console.log(isValid);
  };
  return (
    <div>
      <Drawer headerName="Update Question">
        <Header
          standard={account !== null && account.standard.name}
          subjectName={account !== undefined && account.subject.name}
          unitName={
            account !== null &&
            account.unit.number +
              "." +
              account.unit.name +
              " -    Question #" +
              questionNumber
          }
        />
        {alert && <Alert severity={alert.alertType} message={alert.message} />}

        <div style={{ width: "100%" }}>
          <div style={{ ...row }}>
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flex: 1,
                  flexGrow: 1,
                  justifyContent: "space-between",
                }}
              >
                <CustomSubunitSelect
                  required={true}
                  label="Subunits"
                  value={subunitID}
                  options={subunits}
                  error={subunitError}
                  firstMenu="Select Subunit"
                  onChange={onSubunitChange}
                />
                <CustomConceptSelect
                  required={true}
                  label="Concepts"
                  options={concepts}
                  value={conceptID}
                  error={conceptError}
                  onChange={onConceptChange}
                  firstMenu="Select Concept"
                />
                <CustomSelect
                  required={true}
                  value={difficultyLevelCode}
                  label="Difficulty Level"
                  options={difficultyLevels}
                  error={difficultyLevelError}
                  onChange={onDifficultyLevelChange}
                  firstMenu="Select Difficulty Level"
                />
                <CustomSelect
                  required={true}
                  value={objectiveTypeCode}
                  label="Objective Type"
                  options={objectiveTypes}
                  error={objectiveTypeError}
                  onChange={onObjectiveTypeChange}
                  firstMenu="Select Objective Type"
                />
                <CustomSelect
                  label="Exam"
                  value={exam}
                  onChange={onExamChange}
                  options={examsList}
                  firstMenu="Select Exam"
                />
                <CustomSelect
                  value={year}
                  label="Exam Year"
                  options={years}
                  onChange={onYearChange}
                  firstMenu="Select Exam Year"
                />
              </div>
              <div
                style={{
                  ...row,
                }}
              >
                <div
                  style={{
                    backgroundColor: hintError ? "red" : "yellow",
                    ...label,
                  }}
                >
                  <Typography variant="h5" component="h4">
                    P
                  </Typography>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignContent: "center",
                    //textAlign: "center",
                    alignItems: "center",
                    justifyContent: "center",
                    alignSelf: "center",
                    margin: 8,
                  }}
                >
                  <div style={{ marginRight: 16 }}>
                    <FormControl
                      variant="filled"
                      className={classes.formControl}
                    >
                      <InputLabel id="demo-simple-select-filled-label">
                        Please select the problem
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-filled-label"
                        id="demo-simple-select-filled"
                        value={pendingItemLocal}
                        onChange={handlePendingItemChange}
                      >
                        <MenuItem value={0}>
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value={"Diagram is missing"}>
                          Diagram is missing
                        </MenuItem>

                        <MenuItem value={"Formula is missing"}>
                          Formula is missing
                        </MenuItem>
                        <MenuItem value={"Question is incomplete"}>
                          Question is incomplete
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignContent: "center",
                }}
              >
                <div
                  style={{
                    backgroundColor: questionError ? "red" : "lightpink",

                    ...label,
                  }}
                >
                  <Typography variant="h5" component="h4">
                    Q
                  </Typography>
                </div>

                <div style={{ margin: 8 }}>
                  <Summernote
                    height="100"
                    width="1024"
                    data={question}
                    name="question"
                    onChange={onQuestionChange}
                  />
                </div>
              </div>

              <div
                style={{
                  ...row,
                  width: 1088,
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    ...row,
                  }}
                >
                  <div
                    style={{
                      backgroundColor: choiceAError ? "red" : "wheat",
                      ...label,
                    }}
                  >
                    <Typography variant="h5" component="h4">
                      A
                    </Typography>
                  </div>
                  <div
                    style={{
                      ...container,
                    }}
                  >
                    <div style={{ margin: 8 }}>
                      <Summernote
                        height="50"
                        width="400"
                        data={choiceA}
                        name="Choice A"
                        onChange={onChoiceAChange}
                      />
                    </div>
                    <ToggleChoice
                      onToggle={onChoiceAToggle}
                      selected={choiceACorrect}
                      error={emptyChoiceError}
                    />
                  </div>
                </div>
                <div
                  style={{
                    ...row,
                    marginRight: 16,
                  }}
                >
                  <div
                    style={{
                      backgroundColor: choiceBError ? "red" : "wheat",
                      ...label,
                    }}
                  >
                    <Typography variant="h5" component="h4">
                      B
                    </Typography>
                  </div>
                  <div
                    style={{
                      ...container,
                    }}
                  >
                    <div style={{ margin: 8 }}>
                      <Summernote
                        height="50"
                        width="400"
                        data={choiceB}
                        name="choiceB"
                        onChange={onChoiceBChange}
                      />
                    </div>
                    <ToggleChoice
                      onToggle={onChoiceBToggle}
                      selected={choiceBCorrect}
                      error={emptyChoiceError}
                    />
                  </div>
                </div>
              </div>

              <div
                style={{
                  ...row,
                  width: 1088,
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    ...row,
                    marginRight: 8,
                  }}
                >
                  <div
                    style={{
                      backgroundColor: choiceCError ? "red" : "wheat",
                      ...label,
                    }}
                  >
                    <Typography variant="h5" component="h4">
                      C
                    </Typography>
                  </div>
                  <div
                    style={{
                      ...container,
                    }}
                  >
                    <div style={{ margin: 8 }}>
                      <Summernote
                        height="50"
                        width="400"
                        data={choiceC}
                        name="choiceC"
                        onChange={onChoiceCChange}
                      />
                    </div>
                    <ToggleChoice
                      onToggle={onChoiceCToggle}
                      selected={choiceCCorrect}
                      error={emptyChoiceError}
                    />
                  </div>
                </div>
                <div
                  style={{
                    ...row,
                    marginRight: 8,
                  }}
                >
                  <div
                    style={{
                      backgroundColor: choiceDError ? "red" : "wheat",
                      ...label,
                    }}
                  >
                    <Typography variant="h5" component="h4">
                      D
                    </Typography>
                  </div>
                  <div
                    style={{
                      ...container,
                    }}
                  >
                    <div style={{ margin: 8 }}>
                      <Summernote
                        height="50"
                        width="400"
                        data={choiceD}
                        name="choiceD"
                        onChange={onChoiceDChange}
                      />
                    </div>
                    <ToggleChoice
                      onToggle={onChoiceDToggle}
                      selected={choiceDCorrect}
                      error={emptyChoiceError}
                    />
                  </div>
                </div>
              </div>

              <div
                style={{
                  ...row,
                }}
              >
                <div
                  style={{
                    backgroundColor: solutionError ? "red" : "lightblue",
                    ...label,
                  }}
                >
                  <Typography variant="h5" component="h4">
                    S
                  </Typography>
                </div>
                {/* <CustomMultilineTextfield
                  //label="Solution"
                  required={true}
                  onChange={onSolutionChange}
                  width={1024}
                  rows={10}
                  error={solutionError}
                /> */}
                <div style={{ margin: 8 }}>
                  <Summernote
                    height="150"
                    width="1024"
                    data={solution}
                    name="Solution"
                    onChange={onSolutionChange}
                  />
                </div>
              </div>
              <div
                style={{
                  ...row,
                }}
              >
                <div
                  style={{
                    backgroundColor: hintError ? "red" : "green",
                    ...label,
                  }}
                >
                  <Typography variant="h5" component="h4">
                    H
                  </Typography>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignContent: "center",
                    //textAlign: "center",
                    alignItems: "center",
                    justifyContent: "center",
                    alignSelf: "center",
                    margin: 8,
                  }}
                >
                  <div
                    style={{
                      marginRight: 16,
                    }}
                  >
                    {/* <ToggleChoice
                      selected={isHintAvail}
                      onToggle={() => setisHintAvail(!isHintAvail)}
                      error={hintError}
                    /> */}
                    <Checkbox
                      color="primary"
                      checked={isHintAvail}
                      onChange={() => setisHintAvail(!isHintAvail)}
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />
                  </div>
                  {isHintAvail ? (
                    <div style={{ marginRight: 16 }}>
                      <Summernote
                        height="60"
                        width="968"
                        data={hinta}
                        name="hint"
                        onChange={onHintChange}
                      />
                    </div>
                  ) : (
                    <Typography
                      style={{ marginRight: 16, color: "grey" }}
                      component="h6"
                    >
                      If there is a hint click here
                    </Typography>
                  )}
                </div>
              </div>
            </div>
            <div
              style={{
                //backgroundColor: "lightskyblue",
                width: 420,
                //height: 540,
                padding: 16,
                marginLeft: 16,
                marginRight: 16,
              }}
            >
              <div style={{ textAlign: "center" }}>
                <Typography variant="h5">Preview</Typography>
                {/* <ToggleGroup /> */}
              </div>
              <div style={{ margin: 16, backgroundColor: "lightgrey" }}>
                <Mathjax math={preview} />
              </div>
            </div>
          </div>
          {/* <input type="file" required accept=".xlsx" onChange={handleUpload} />
          <Button on>upload</Button> */}
          <div
            style={{
              marginLeft: 66,
              justifyContent: "flex-end",
              display: "flex",
              width: 1020,
            }}
          >
            {/* <Button
              size="small"
              variant="contained"
              color="secondary"
              disableElevation
              onClick={onClickDeleteQuestion}
              style={{ marginRight: 8 }}
            >
              Delete
            </Button> */}
            <Button
              size="small"
              variant="contained"
              color="primary"
              disableElevation
              onClick={onCLickToUpdateQuestion}
            >
              Update
            </Button>
          </div>
          <div>
            <input
              type="file"
              name="myImage"
              style={{ width: 400 }}
              onChange={fileChangeHandler}
            />
            <input style={{ width: 400 }} value={image} />

            <CopyToClipboard text={image} onCopy={() => setIsCopied(true)}>
              <button>Click to Copy</button>
            </CopyToClipboard>

            {isCopied ? <span style={{ color: "green" }}>Copied.</span> : null}
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default UpdateQuestion;
