import axios from "axios";
import { baseUrl } from "../../config/baseUrl";
import { setAlert, removeAlert } from "../actions/Alert";
import { SET_ALERT, REMOVE_ALERT } from "../actions/Alert";

export const GET_DIFFICULTY_LEVELS_REQUEST = "GET_DIFFICULTY_LEVELS_REQUEST";
export const GET_DIFFICULTY_LEVELS_FAILURE = "GET_DIFFICULTY_LEVELS_FAILURE";
export const GET_DIFFICULTY_LEVELS_SUCCESS = "GET_DIFFICULTY_LEVELS_SUCCESS";

export const GET_OBJECTIVE_TYPES_REQUEST = "GET_OBJECTIVE_TYPES_REQUEST";
export const GET_OBJECTIVE_TYPES_FAILURE = "GET_OBJECTIVE_TYPES_FAILURE";
export const GET_OBJECTIVE_TYPES_SUCCESS = "GET_OBJECTIVE_TYPES_SUCCESS";

export const GET_SUBUNITS_REQUEST = "GET_SUBUNITS_REQUEST";
export const GET_SUBUNITS_FAILURE = "GET_SUBUNITS_FAILURE";
export const GET_SUBUNITS_SUCCESS = "GET_SUBUNITS_SUCCESS";

export const GET_SUBJECTS_REQUEST = "GET_SUBJECTS_REQUEST";
export const GET_SUBJECTS_FAILURE = "GET_SUBJECTS_FAILURE";
export const GET_SUBJECTS_SUCCESS = "GET_SUBJECTS_SUCCESS";

export const GET_UNITS_REQUEST = "GET_UNITS_REQUEST";
export const GET_UNITS_FAILURE = "GET_UNITS_FAILURE";
export const GET_UNITS_SUCCESS = "GET_UNITS_SUCCESS";

export const CREATE_QUESTION_REQUEST = "CREATE_QUESTION_REQUEST";
export const CREATE_QUESTION_FAILURE = "CREATE_QUESTION_FAILURE";
export const CREATE_QUESTION_SUCCESS = "CREATE_QUESTION_SUCCESS";

export const UPDATE_QUESTION_REQUEST = "UPDATE_QUESTION_REQUEST";
export const UPDATE_QUESTION_FAILURE = "UPDATE_QUESTION_FAILURE";
export const UPDATE_QUESTION_SUCCESS = "UPDATE_QUESTION_SUCCESS";

export const DELETE_QUESTION_REQUEST = "DELETE_QUESTION_REQUEST";
export const DELETE_QUESTION_FAILURE = "DELETE_QUESTION_FAILURE";
export const DELETE_QUESTION_SUCCESS = "DELETE_QUESTION_SUCCESS";

export const ASSIGN_UNIT_REQUEST = "ASSIGN_UNIT_REQUEST";
export const ASSIGN_UNIT_FAILURE = "ASSIGN_UNIT_FAILURE";
export const ASSIGN_UNIT_SUCCESS = "ASSIGN_UNIT_SUCCESS";

export const GET_QUESTIONS_REQUEST = "GET_QUESTIONS_REQUEST";
export const GET_QUESTIONS_FAILURE = "GET_QUESTIONS_FAILURE";
export const GET_QUESTIONS_SUCCESS = "GET_QUESTIONS_SUCCESS";
export const GET_QUESTIONS_BY_UNIT_ID_REQUEST =
  "GET_QUESTIONS_BY_UNIT_ID_REQUEST";
export const GET_QUESTIONS_BY_UNIT_ID_FAILURE =
  "GET_QUESTIONS_BY_UNIT_ID_FAILURE";
export const GET_QUESTIONS_BY_UNIT_ID_SUCCESS =
  "GET_QUESTIONS_BY_UNIT_ID_SUCCESS";

export const GET_CONCEPTS_BY_SUBUNIT_REQUEST =
  "GET_CONCEPTS_BY_SUBUNIT_REQUEST";
export const GET_CONCEPTS_BY_SUBUNIT_FAILURE =
  "GET_CONCEPTS_BY_SUBUNIT_FAILURE";
export const GET_CONCEPTS_BY_SUBUNIT_SUCCESS =
  "GET_CONCEPTS_BY_SUBUNIT_SUCCESS";

export const getDifficultyLevel = (boards) => async (dispatch) => {
  const token = localStorage.getItem("token");
  dispatch({
    type: GET_DIFFICULTY_LEVELS_REQUEST,
  });
  if (token) {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const res = await axios.get(`${baseUrl}/api/difficulty-level`, config);

      if (res.status === 200) {
        dispatch({
          type: GET_DIFFICULTY_LEVELS_SUCCESS,
          payload: res.data.difficultyLevel,
        });
      } else {
        dispatch({
          type: GET_DIFFICULTY_LEVELS_FAILURE,
          payload: res.data.message,
        });
      }
    } catch (err) {
      dispatch({
        type: GET_DIFFICULTY_LEVELS_FAILURE,
        payload: err.response.data.error,
      });
    }
  }
};
export const getObjectiveTypes = () => async (dispatch) => {
  const token = localStorage.getItem("token");
  dispatch({
    type: GET_OBJECTIVE_TYPES_REQUEST,
  });
  if (token) {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const res = await axios.get(`${baseUrl}/api/objective-types`, config);

      if (res.status === 200) {
        dispatch({
          type: GET_OBJECTIVE_TYPES_SUCCESS,
          payload: res.data.objectiveTypes,
        });
      } else {
        dispatch({
          type: GET_OBJECTIVE_TYPES_FAILURE,
          payload: res.data.message,
        });
      }
    } catch (err) {
      dispatch({
        type: GET_OBJECTIVE_TYPES_FAILURE,
        payload: err.response.data.error,
      });
    }
  }
};

export const getSubjects = (courseId) => async (dispatch) => {
  const token = localStorage.getItem("token");

  dispatch({
    type: GET_SUBJECTS_REQUEST,
  });
  if (token) {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const res = await axios.get(`${baseUrl}/api/subject/${courseId}`, config);
    // console.log("cons subject" + JSON.stringify(res));
    if (res.status === 200) {
      dispatch({
        type: GET_SUBJECTS_SUCCESS,
        payload: res.data.subjects,
      });
    } else {
      dispatch({
        type: GET_SUBJECTS_FAILURE,
        payload: res.data.error,
      });
    }
  }
};

export const getUnits = (subjectId) => async (dispatch) => {
  const token = localStorage.getItem("token");

  dispatch({
    type: GET_UNITS_REQUEST,
  });
  if (token) {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const res = await axios.get(`${baseUrl}/api/unit/${subjectId}`, config);
    // console.log("cons unit" + JSON.stringify(res));
    if (res.status === 200) {
      dispatch({
        type: GET_UNITS_SUCCESS,
        payload: res.data.subjects,
      });
    } else {
      dispatch({
        type: GET_UNITS_FAILURE,
        payload: res.data.error,
      });
    }
  }
};

export const getSubunits = () => async (dispatch) => {
  const token = localStorage.getItem("token");
  dispatch({
    type: GET_SUBUNITS_REQUEST,
  });
  if (token) {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const res = await axios.get(`${baseUrl}/api/subunit/unit`, config);
    // console.log("subunits res" + JSON.stringify(res));
    if (res.status === 200) {
      dispatch({
        type: GET_SUBUNITS_SUCCESS,
        payload: res.data.subunits,
      });
    } else {
      dispatch({
        type: GET_SUBUNITS_FAILURE,
        payload: res.data.error,
      });
    }
  }
};

export const getConcepts = (subunitId) => async (dispatch) => {
  const token = localStorage.getItem("token");

  // console.log("subunitId" + subunitId);
  dispatch({
    type: GET_CONCEPTS_BY_SUBUNIT_REQUEST,
  });
  if (token) {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const res = await axios.get(
      `${baseUrl}/api/concept/subunit/${subunitId}`,
      config
    );
    // console.log("cons res" + JSON.stringify(res));
    if (res.status === 200) {
      dispatch({
        type: GET_CONCEPTS_BY_SUBUNIT_SUCCESS,
        payload: res.data.concepts,
      });
    } else {
      dispatch({
        type: GET_CONCEPTS_BY_SUBUNIT_FAILURE,
        payload: res.data.error,
      });
    }
  }
};

export const createQuestion = (qdata) => async (dispatch) => {
  const token = localStorage.getItem("token");

  // console.log("subunitId" + subunitId);
  dispatch({
    type: CREATE_QUESTION_REQUEST,
  });
  if (token) {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const body = JSON.stringify(qdata);
    try {
      const res = await axios.post(`${baseUrl}/api/question/new`, body, config);
      // console.log("cons res" + JSON.stringify(res));
      if (res.status === 200) {
        dispatch({
          type: CREATE_QUESTION_SUCCESS,
          payload: res.data.message,
        });

        dispatch({
          type: SET_ALERT,
          payload: { message: res.data.message, alertType: "success" },
        });
        setTimeout(() => {
          dispatch({
            type: REMOVE_ALERT,
          });
        }, 5000);
      } else {
        dispatch({
          type: SET_ALERT,
          payload: { message: res.data.error, alertType: "error" },
        });
        setTimeout(() => {
          dispatch({
            type: REMOVE_ALERT,
          });
        }, 5000);
        dispatch({
          type: CREATE_QUESTION_FAILURE,
          payload: res.data.error,
        });
      }
    } catch (error) {
      dispatch({
        type: SET_ALERT,
        payload: { message: error, alertType: "error" },
      });
      setTimeout(() => {
        dispatch({
          type: REMOVE_ALERT,
        });
      }, 5000);
      dispatch({
        type: CREATE_QUESTION_FAILURE,
        payload: error,
      });
    }
  }
};

export const update = (qdata) => async (dispatch) => {
  const token = localStorage.getItem("token");

  //console.log("update" );
  dispatch({
    type: UPDATE_QUESTION_REQUEST,
  });
  if (token) {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const body = JSON.stringify(qdata);
    try {
      const res = await axios.put(
        `${baseUrl}/api/question/update`,
        body,
        config
      );
      if (res.status === 200) {
        dispatch({
          type: UPDATE_QUESTION_SUCCESS,
          payload: res.data.message,
        });

        dispatch({
          type: SET_ALERT,
          payload: { message: res.data.message, alertType: "success" },
        });
        setTimeout(() => {
          dispatch({
            type: REMOVE_ALERT,
          });
        }, 5000);
      } else {
        dispatch({
          type: SET_ALERT,
          payload: { message: res.data.error, alertType: "error" },
        });
        setTimeout(() => {
          dispatch({
            type: REMOVE_ALERT,
          });
        }, 5000);
        dispatch({
          type: UPDATE_QUESTION_FAILURE,
          payload: res.data.error,
        });
      }
    } catch (error) {
      dispatch({
        type: SET_ALERT,
        payload: { message: error, alertType: "error" },
      });
      setTimeout(() => {
        dispatch({
          type: REMOVE_ALERT,
        });
      }, 5000);
      dispatch({
        type: UPDATE_QUESTION_FAILURE,
        payload: error,
      });
    }
  }
};

export const deleteQuestion = (questionId) => async (dispatch) => {
  const token = localStorage.getItem("token");

  //console.log("update" );
  dispatch({
    type: DELETE_QUESTION_REQUEST,
  });
  if (token) {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const res = await axios.delete(
      `${baseUrl}/api/question/delete/${questionId}`,
      config
    );
    // console.log("cons res" + JSON.stringify(res));
    if (res.status === 200) {
      dispatch({
        type: DELETE_QUESTION_SUCCESS,
        payload: res.data.message,
      });

      dispatch({
        type: DELETE_QUESTION_SUCCESS,
        payload: res.data.message,
      });
    } else {
      dispatch({
        type: DELETE_QUESTION_FAILURE,
        payload: res.data.error,
      });
    }
  }
};

export const getQuestions = (userId) => async (dispatch) => {
  const token = localStorage.getItem("token");

  dispatch({
    type: GET_QUESTIONS_REQUEST,
  });
  if (token) {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const res = await axios.get(`${baseUrl}/api/question/unit-wise`, config);
    //console.log("cons questions" + JSON.stringify(res));
    if (res.status === 200) {
      dispatch({
        type: GET_QUESTIONS_SUCCESS,
        payload: res.data.questions,
      });
      dispatch({
        type: SET_ALERT,
        payload: { msg: "Question has been Saved", alertType: "sucess" },
      });
      setTimeout(() => {
        dispatch({
          type: REMOVE_ALERT,
        });
      }, 5000);
    } else {
      dispatch({
        type: GET_QUESTIONS_FAILURE,
        payload: res.data.error,
      });
    }
  }
};

export const getQuestionsByUnitId = (unitId) => async (dispatch) => {
  const token = localStorage.getItem("token");

  dispatch({
    type: GET_QUESTIONS_BY_UNIT_ID_REQUEST,
  });
  if (token) {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const res = await axios.get(
      `${baseUrl}/api/question/unit/${unitId}`,
      config
    );
    // console.log(" questions" + JSON.stringify(res.data));
    if (res.status === 200) {
      dispatch({
        type: GET_QUESTIONS_BY_UNIT_ID_SUCCESS,
        payload: res.data.questions,
      });
    } else {
      dispatch({
        type: GET_QUESTIONS_BY_UNIT_ID_FAILURE,
        payload: res.data.error,
      });
    }
  }
};

export const assignUnit = ({ unitId, subjectId, userId }) => async (
  dispatch
) => {
  const token = localStorage.getItem("token");

  dispatch({
    type: ASSIGN_UNIT_REQUEST,
  });
  if (token) {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    //const body = JSON.stringify({ unitId, userId, subjectId });
    const res = await axios.put(
      `${baseUrl}/api/user/assign-unit/${userId}/${subjectId}/${unitId}`,
      config
    );
    //console.log("cons questions" + JSON.stringify(res));
    if (res.status === 200) {
      dispatch({
        type: ASSIGN_UNIT_SUCCESS,
        payload: res.data.message,
      });
    } else {
      dispatch({
        type: ASSIGN_UNIT_FAILURE,
        payload: res.data.error,
      });
    }
  }
};
