//import isEmpty from "../../validation/is-empty";
import {
  GET_OBJECTIVE_TYPE_BY_BOARD_FAILURE,
  GET_OBJECTIVE_TYPE_BY_BOARD_REQUEST,
  GET_OBJECTIVE_TYPE_BY_BOARD_SUCCESS,
  GET_OBJECTIVE_TYPE_BY_BOARD_USER_FAILURE,
  GET_OBJECTIVE_TYPE_BY_BOARD_USER_REQUEST,
  GET_OBJECTIVE_TYPE_BY_BOARD_USER_SUCCESS,
} from "../actions/ObjectiveType";

const initialState = {
  objectiveTypes: [],
  message: null,
  error: null,
  isLoading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_OBJECTIVE_TYPE_BY_BOARD_REQUEST:
    case GET_OBJECTIVE_TYPE_BY_BOARD_USER_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_OBJECTIVE_TYPE_BY_BOARD_SUCCESS:
    case GET_OBJECTIVE_TYPE_BY_BOARD_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        objectiveTypes: action.payload,
      };
    case GET_OBJECTIVE_TYPE_BY_BOARD_FAILURE:
    case GET_OBJECTIVE_TYPE_BY_BOARD_USER_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
