import axios from "axios";
import { baseUrl } from "../../config/baseUrl";
import { setAlert, removeAlert } from "../actions/Alert";
import { SET_ALERT, REMOVE_ALERT } from "../actions/Alert";
export const GET_STANDARDS_BY_BOARD_REQUEST = "GET_STANDARDS_BY_BOARD_REQUEST";
export const GET_STANDARDS_BY_BOARD_FAILURE = "GET_STANDARDS_BY_BOARD_FAILURE";
export const GET_STANDARDS_BY_BOARD_SUCCESS = "GET_STANDARDS_BY_BOARD_SUCCESS";

export const getStandardsByBoard = (boardId) => async (dispatch) => {
  const token = localStorage.getItem("token");
  dispatch({
    type: GET_STANDARDS_BY_BOARD_REQUEST,
  });
  if (token) {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const res = await axios.get(
        `${baseUrl}/api/standard/board/${boardId}`,
        config
      );

      if (res.status === 200) {
        dispatch({
          type: GET_STANDARDS_BY_BOARD_SUCCESS,
          payload: res.data.standards,
        });
      } else {
        dispatch({
          type: GET_STANDARDS_BY_BOARD_FAILURE,
          payload: res.data.error,
        });
      }
    } catch (err) {
      dispatch({
        type: GET_STANDARDS_BY_BOARD_FAILURE,
        payload: err,
      });
    }
  }
};
