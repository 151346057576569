import React, { useEffect, useState } from "react";
import Drawer from "../components/Drawer";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip, Fab, Typography, Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import CustomSelect from "../components/CustomSelect";
import Theme from "../Theme";
import {
  getQuestionsByUnitId,
  getSubjects,
  getUnits,
  assignUnit,
} from "../store/actions/Content";
import { getAccountDetail } from "../store/actions/Account";

const Account = (props) => {
  const dispatch = useDispatch();
  const subjects = useSelector((state) => state.content.subjects);
  const units = useSelector((state) => state.content.units);
  const user = useSelector((state) => state.auth.user);

  const account = useSelector((state) => state.account.account);

  const [subject, setSubject] = useState("");
  const [unit, setUnit] = useState("");

  const row = { display: "flex", flexDirection: "row", alignContent: "center" };

  useEffect(() => {
    // dispatch(getQuestions(user.id));
    dispatch(getAccountDetail(user.id));
    dispatch(getSubjects(account.course._id));
  }, [dispatch]);

  const onSubjectChange = (e) => {
    e.preventDefault();
    setSubject(e.target.value);
    dispatch(getUnits(e.target.value));
  };
  const onUnitChange = (e) => {
    e.preventDefault();
    setUnit(e.target.value);
    dispatch(getQuestionsByUnitId(e.target.value));
  };

  const onClickAssignUnit = (e) => {
    e.preventDefault();
    dispatch(assignUnit({ subjectId: subject, unitId: unit, userId: user.id }));
  };
  return (
    <div>
      <Drawer headerName="Account" user={account !== null && account.firstName}>
        <div
          style={{
            //width: 800,
            alignItems: "center",
            //justifyContent: "center",
            //alignSelf: "center",
            //alignContent: "center",
            //flex: 1,
            // display: "flex",
            flexDirection: "column",
          }}
        >
          <div style={{ ...row }}>
            <div>
              <div
                style={{
                  ...row,
                  marginLeft: 16,
                  marginTop: 16,
                  backgroundColor: "lightgray",
                  padding: 8,
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography style={{ marginRight: 16 }}>Name </Typography>
                <Typography>{account.name}</Typography>
              </div>
              <div
                style={{
                  ...row,
                  marginLeft: 16,
                  marginTop: 16,
                  backgroundColor: "lightgray",
                  padding: 8,
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography style={{ marginRight: 16 }}>email </Typography>
                <Typography>{account.email}</Typography>
              </div>
              <div
                style={{
                  ...row,
                  marginLeft: 16,
                  marginTop: 16,
                  backgroundColor: "lightgray",
                  padding: 8,
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography style={{ marginRight: 16 }}>Standard </Typography>
                <Typography>{account.standard.name}</Typography>
              </div>
              <div
                style={{
                  ...row,
                  marginLeft: 16,
                  marginTop: 16,
                  backgroundColor: "lightgray",
                  padding: 8,
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography style={{ marginRight: 16 }}>Subject </Typography>
                <Typography>{account.subject.name}</Typography>
              </div>
              <div
                style={{
                  ...row,
                  marginLeft: 16,
                  marginTop: 16,
                  backgroundColor: "lightgray",
                  padding: 8,
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography style={{ marginRight: 16 }}>Unit </Typography>
                <Typography>{account.unit.unitNumber}.{account.unit.name}</Typography>
              </div>

              {/* <div
                style={{
                  marginLeft: 16,
                  marginTop: 24,
                  backgroundColor: "silver",
                  padding: 8,
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography style={{ marginRight: 16 }}>Change Unit</Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flex: 1,
                  flexGrow: 1,
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginLeft: 8,
                }}
              >
                <CustomSelect
                  required={true}
                  label="Subjects"
                  value={subject}
                  options={subjects}
                  error=""
                  firstMenu="Select Subject"
                  onChange={onSubjectChange}
                />
                <CustomSelect
                  required={true}
                  label="Units"
                  options={units}
                  value={unit}
                  error=""
                  onChange={onUnitChange}
                  firstMenu="Select Unit"
                />
                <div
                  style={{
                    marginLeft: 16,
                  }}
                >
                  <Button
                    size="large"
                    variant="contained"
                    color="primary"
                    disableElevation
                    onClick={onClickAssignUnit}
                  >
                    Change
                  </Button>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default Account;
