//import isEmpty from "../../validation/is-empty";
import {
  GET_COURSES_BY_STANDARD_FAILURE,
  GET_COURSES_BY_STANDARD_REQUEST,
  GET_COURSES_BY_STANDARD_SUCCESS,
} from "../actions/Course";

const initialState = {
  courses: [],
  message: null,
  error: null,
  isLoading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_COURSES_BY_STANDARD_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_COURSES_BY_STANDARD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        courses: action.payload,
      };
    case GET_COURSES_BY_STANDARD_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
