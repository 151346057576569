import React from "react";
import MathJax from "react-mathjax-preview";
const Mathjx = (props) => {
  return (
    <div id="react-mathjax-preview">
      <MathJax id="react-mathjax-preview" math={props.math} />
    </div>
  );
};

export default Mathjx;
