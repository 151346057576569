//import isEmpty from "../../validation/is-empty";
import {
  GET_SUBJECTS_BY_COURSE_SUCCESS,
  GET_SUBJECTS_BY_COURSE_REQUEST,
  GET_SUBJECTS_BY_COURSE_FAILURE,
  GET_ALL_SUBJECTS_FAILURE,
  GET_ALL_SUBJECTS_REQUEST,
  GET_ALL_SUBJECTS_SUCCESS,
} from "../actions/Subject";

const initialState = {
  subjects: [],
  message: null,
  error: null,
  isLoading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ALL_SUBJECTS_REQUEST:
    case GET_SUBJECTS_BY_COURSE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_ALL_SUBJECTS_SUCCESS:
    case GET_SUBJECTS_BY_COURSE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        subjects: action.payload,
      };
    case GET_ALL_SUBJECTS_FAILURE:
    case GET_SUBJECTS_BY_COURSE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
