import React, { useEffect, useState } from "react";
import Drawer from "../components/Drawer";
import CustomTextField from "../components/CustomTextField";
import CustomSelect from "../components/CustomSelect";

import Alert from "../components/Alert";
//import XLSX from "xlsx";
import { useDispatch, useSelector } from "react-redux";
import { Button, IconButton, Typography } from "@material-ui/core";
import { createUser } from "../store/actions/User";
const AddUser = (props) => {
  const dispatch = useDispatch();

  const alert = useSelector((state) => state.alert.alert);
  const roles = [
    { _id: "ADMIN", name: "ADMIN" },
    { _id: "FORMATTER", name: "FORMATTER" },
    { _id: "CONTENT_CREATOR", name: "CONTENT_CREATOR" },
  ];

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("");

  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [roleError, setRoleError] = useState("");
  if (alert !== null && alert.alertType == "success") {
    props.history.goBack();
  }
  const userNameChange = (e) => {
    e.preventDefault();
    setName(e.target.value);
    setNameError("");
  };
  const emailChange = (e) => {
    e.preventDefault();
    setEmail(e.target.value);
    setEmailError("");
  };
  const passwordChange = (e) => {
    e.preventDefault();
    setPassword(e.target.value);
    setPasswordError("");
  };
  const roleChange = (e) => {
    e.preventDefault();
    setRole(e.target.value);
    setRoleError("");
  };
  // validation

  const validate = () => {
    if (name == "") {
      setNameError("Name is required");
      return false;
    }
    if (email == "") {
      setEmailError("Email is required");
      return false;
    }
    if (password == "") {
      setPasswordError("Password is required");
      return false;
    }
    if (role == "") {
      setRoleError("Role is required");
      return false;
    }

    return true;
  };
  const onClickTocreateSubunits = () => {
    const isValid = validate();

    if (isValid) {
      const user = {
        name,
        email,
        password,
        role,
      };
      console.log("ok" + user);
      dispatch(createUser(user));
    }
  };
  return (
    <div>
      <Drawer headerName="Add User">
        {alert && <Alert severity={alert.alertType} message={alert.message} />}
        <div
          style={{
            marginLeft: 16,
            marginTop: 24,
            backgroundColor: "silver",
            padding: 8,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography style={{ marginRight: 16 }}>Add User</Typography>
        </div>
        <div style={{ width: "100%" }}>
          <div style={{ margin: 8 }}>
            <CustomTextField
              label="User Name"
              value={name}
              onChange={userNameChange}
              error={nameError}
              width={300}
            />
            <CustomTextField
              label="Email"
              value={email}
              onChange={emailChange}
              error={emailError}
              width={300}
            />
            <CustomTextField
              label="Password"
              value={password}
              onChange={passwordChange}
              error={passwordError}
              width={300}
            />
            <CustomSelect
              required={true}
              value={role}
              label="Roles"
              options={roles}
              error={roleError}
              onChange={roleChange}
              firstMenu="Select Role"
              minWidth={300}
            />
            <div
              style={{
                display: "flex",
                margin: 8,
              }}
            >
              <Button
                size="small"
                variant="contained"
                color="primary"
                disableElevation
                onClick={onClickTocreateSubunits}
              >
                Create
              </Button>
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default AddUser;
