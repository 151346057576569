import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
//import { BrowserHistory } from "history";

import "./App.css";
import PrivateRoute from "./routing/PrivateRoute";
import Dashboard from "./pages/Dashboard";
import NewQuestion from "./pages/NewQuestion";
import UpdateQuestion from "./pages/UpdateQuestion";
import VerifyQuestions from "./pages/VerifyQuestions";
import SignIn from "./pages/SignIn";
import ForgotPassword from "./pages/ForgotPassword";
import VerifyAccount from "./pages/VerifyAccount";
import PageNotFound from "./pages/PageNotFound";
import { Provider } from "react-redux";
import store from "./redux-store/store";
import Account from "./pages/Account";
import QuestionFileUpload from "./pages/QuestionFileUpload";
import Subjects from "./pages/Subjects";
import UpdateSubject from "./pages/UpdateSubject";
import Units from "./pages/Units";

import UpdateUnit from "./pages/UpdateUnit";
import Subunits from "./pages/Subunits";
import Concepts from "./pages/Concepts";
import AddConcepts from "./pages/AddConcepts";
import AddUnits from "./pages/AddUnits";
import AddSubunits from "./pages/AddSubunits";
import Users from "./pages/Users";
import AddUser from "./pages/AddUser";
import AssignWork from "./pages/AssignWork";
import QuestionsPdfMaker from "./pages/QuestionsPdfMaker";

const Routes = (props) => {
  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <PrivateRoute exact path="/subjects" component={Subjects} />
          <PrivateRoute
            exact
            path="/upload-subject-image"
            component={UpdateSubject}
          />
          <PrivateRoute exact path="/units" component={Units} />
          <PrivateRoute exact path="/update-unit" component={UpdateUnit} />
          <PrivateRoute exact path="/subunits" component={Subunits} />
          <PrivateRoute exact path="/concepts" component={Concepts} />
          <PrivateRoute exact path="/add-units" component={AddUnits} />
          <PrivateRoute exact path="/add-subunits" component={AddSubunits} />
          <PrivateRoute exact path="/add-concepts" component={AddConcepts} />
          <PrivateRoute exact path="/create-question" component={NewQuestion} />
          <PrivateRoute
            exact
            path="/upload-question-file"
            component={QuestionFileUpload}
          />
          <PrivateRoute
            exact
            path="/update-question"
            component={UpdateQuestion}
          />
          <PrivateRoute
            exact
            path="/verify-question"
            component={VerifyQuestions}
          />
          <PrivateRoute
            exact
            path="/questions-pdf"
            component={QuestionsPdfMaker}
          />

          <PrivateRoute
            exact
            path="/admin-panel"
            component={QuestionFileUpload}
          />
          <PrivateRoute exact path="/users" component={Users} />
          <PrivateRoute exact path="/add-user" component={AddUser} />
          <PrivateRoute exact path="/assign-work" component={AssignWork} />
          <PrivateRoute exact path="/account" component={Account} />

          <Route exact path="/sign-in" component={SignIn} />
          <Route exact path="/forgot-password" component={ForgotPassword} />
          <Route exact path="/verify-account" component={VerifyAccount} />
          <PrivateRoute exact path="/" component={Dashboard} />
          <Route component={PageNotFound} />
        </Switch>
      </Router>
    </Provider>
  );
};

export default Routes;
