import axios from "axios";
import { baseUrl } from "../../config/baseUrl";
import { setAlert, removeAlert } from "../actions/Alert";
import { SET_ALERT, REMOVE_ALERT } from "../actions/Alert";
export const GET_CONCEPTS_BY_SUBUNIT_REQUEST =
  "GET_CONCEPTS_BY_SUBUNIT_REQUEST";
export const GET_CONCEPTS_BY_SUBUNIT_FAILURE =
  "GET_CONCEPTS_BY_SUBUNIT_FAILURE";
export const GET_CONCEPTS_BY_SUBUNIT_SUCCESS =
  "GET_CONCEPTS_BY_SUBUNIT_SUCCESS";

export const CREATE_CONCEPTS_REQUEST = "CREATE_CONCEPTS_REQUEST";
export const CREATE_CONCEPTS_FAILURE = "CREATE_CONCEPTS_FAILURE";
export const CREATE_CONCEPTS_SUCCESS = "CREATE_CONCEPTS_SUCCESS";

export const getConceptsBySubunit = (subunitId) => async (dispatch) => {
  const token = localStorage.getItem("token");
  dispatch({
    type: GET_CONCEPTS_BY_SUBUNIT_REQUEST,
  });
  if (token) {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const res = await axios.get(
        `${baseUrl}/api/concept/subunit/${subunitId}`,
        config
      );

      if (res.status === 200) {
        dispatch({
          type: GET_CONCEPTS_BY_SUBUNIT_SUCCESS,
          payload: res.data.concepts,
        });
      } else {
        dispatch({
          type: GET_CONCEPTS_BY_SUBUNIT_FAILURE,
          payload: res.data.error,
        });
      }
    } catch (err) {
      dispatch({
        type: GET_CONCEPTS_BY_SUBUNIT_FAILURE,
        payload: err,
      });
    }
  }
};

export const createConcepts = ({ subunitId, concepts }) => async (dispatch) => {
  const token = localStorage.getItem("token");
  console.log("create concepts action");
  dispatch({
    type: CREATE_CONCEPTS_REQUEST,
  });
  if (token) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
      },
    };
    const body = JSON.stringify({ subunitId, concepts });
    try {
      const res = await axios.post(
        `${baseUrl}/api/new-concept/create-multiple`,
        body,
        config
      );

      // console.log("create conc res " + res);

      if (res.status === 200) {
        dispatch({
          type: CREATE_CONCEPTS_SUCCESS,
          payload: res.data.response,
        });
        dispatch({
          type: SET_ALERT,
          payload: { message: res.data.message, alertType: "success" },
        });
        setTimeout(() => {
          dispatch({
            type: REMOVE_ALERT,
          });
        }, 5000);
      } else {
        dispatch({
          type: SET_ALERT,
          payload: { message: res.data.error, alertType: "error" },
        });
        setTimeout(() => {
          dispatch({
            type: REMOVE_ALERT,
          });
        }, 5000);
        dispatch({
          type: CREATE_CONCEPTS_FAILURE,
          payload: res.data.error,
        });
      }
    } catch (err) {
      dispatch({
        type: SET_ALERT,
        payload: { message: err, alertType: "error" },
      });
      setTimeout(() => {
        dispatch({
          type: REMOVE_ALERT,
        });
      }, 5000);
      dispatch({
        type: CREATE_CONCEPTS_FAILURE,
        payload: err,
      });
    }
  }
};
