//import isEmpty from "../../validation/is-empty";
import {
  GET_USERS_FAILURE,
  GET_USERS_REQUEST,
  GET_USERS_SUCCESS,
  CREATE_USER_FAILURE,
  CREATE_USER_REQUEST,
  CREATE_USER_SUCCESS,
  ASSIGN_WORK_FAILURE,
  ASSIGN_WORK_REQUEST,
  ASSIGN_WORK_SUCCESS,
} from "../actions/User";

const initialState = {
  users: [],
  message: null,
  error: null,
  isLoading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ASSIGN_WORK_REQUEST:
    case CREATE_USER_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ASSIGN_WORK_SUCCESS:
    case CREATE_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        message: action.payload,
      };
    case ASSIGN_WORK_FAILURE:
    case CREATE_USER_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case GET_USERS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload,
        isLoading: false,
      };
    case GET_USERS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
