//import isEmpty from "../../validation/is-empty";
import {
  GET_BOARDS_BY_CONTENT_PROVIDER_FAILURE,
  GET_BOARDS_BY_CONTENT_PROVIDER_REQUEST,
  GET_BOARDS_BY_CONTENT_PROVIDER_SUCCESS,
} from "../actions/Board";

const initialState = {
  boards: [],
  message: null,
  error: null,
  isLoading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_BOARDS_BY_CONTENT_PROVIDER_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_BOARDS_BY_CONTENT_PROVIDER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        boards: action.payload,
      };
    case GET_BOARDS_BY_CONTENT_PROVIDER_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
