import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import MaterialTable from "material-table";
import Drawer from "../components/Drawer";
import CustomSelect from "../components/CustomSelect";
import { getUsers, createUser } from "../store/actions/User";

import { Button } from "@material-ui/core";
const Unit = (props) => {
  const users = useSelector((state) => state.users.users);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);

  const onClickAddUser = () => {
    props.history.push({
      pathname: "/add-user",
    });
  };
  return (
    <div>
      <Drawer headerName="Users">
        <div style={{ maxWidth: "100%" }}>
          <div className="container">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flex: 1,
                flexGrow: 1,
                justifyContent: "space-between",
                marginBottom: 8,
              }}
            >
              <div></div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  disableElevation
                  onClick={onClickAddUser}
                >
                  Add User
                </Button>
              </div>
            </div>
            <MaterialTable
              columns={[
                { title: "Name", field: "name" },
                { title: "Email", field: "email" },
                { title: "Role", field: "role" },
                { title: "Standard", field: "standard.name" },
                { title: "Subject", field: "subject.name" },
                { title: "Unit Number", field: `unit.number` },
                { title: "Unit", field: `unit.name` },
              ]}
              data={users}
              title="Users"
            />
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default Unit;
