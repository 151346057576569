import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
// import MathJax from "react-mathjax-preview";
import { Card, CardContent } from "@material-ui/core";
import MathJax from "../components/Mathjax";
const UnitQuestionsPdf = () => {
  const questions = useSelector((state) => state.content.questions);
  var half_length = Math.ceil(questions.length / 2);

  var part1 = questions.slice(0, half_length);
  var part2 = questions.slice(half_length, questions.length);
  console.log(JSON.stringify(questions));
  const [subunits, setsubunits] = useState([]);

  useEffect(() => {
    return () => {};
  }, []);
  return (
    <div className="container">
      <div>
        <h6>
          Total Questions :
          {questions.filter((qs) => qs.questionStatus == 1).length}
        </h6>
      </div>
      <div className="d-flex flex-row">
        <h6>Unit : </h6>
        <h6>
          {questions[0].unit.number}.{questions[0].unit.name}
        </h6>
      </div>

      {questions ? (
        questions.map((qs) => {
          if (qs.questionStatus == 1) {
            return (
              <div
                style={{
                  padding: "16px",
                  marginBottom: "4px",
                  borderColor: "gray",
                  borderWidth: 2,
                }}
              >
                <hr
                  style={{
                    color: "#000000",
                    backgroundColor: "#000000",
                    height: 0.3,
                    borderColor: "#000000",
                  }}
                />
                <div className="d-flex flex-row justify-content-between">
                  <div className="d-flex flex-row">
                    <h6>Q Number : </h6>
                    <h6>{qs.questionNumber}</h6>
                  </div>
                  {/* <div className="d-flex flex-row">
                    <h6>Unit : </h6>
                    <h6>
                      {qs.unit.number}.{qs.unit.name}
                    </h6>
                  </div> */}

                  <div className="d-flex flex-row">
                    <h6>Subunit : </h6>
                    <h6>
                      {/* {qs.subunit.number}.{qs.subunit.name} */}
                      <MathJax
                        math={qs.subunit.number + "." + qs.subunit.name}
                      />
                    </h6>
                  </div>
                  <div className="d-flex flex-row">
                    <h6>Concept : </h6>
                    <h6>
                      {/* {qs.concept.number}.{qs.concept.name} */}
                      <MathJax
                        math={qs.concept.number + "." + qs.concept.name}
                      />
                    </h6>
                  </div>
                </div>
                <div className="d-flex flex-row justify-content-between">
                  <div className="d-flex flex-row ">
                    <h6>Difficulty Level : </h6>
                    <h6>{qs.difficultyLevel.name}</h6>
                  </div>
                  <div className="d-flex flex-row">
                    <h6>Objective Type : </h6>
                    <h6>{qs.objectiveType.name}</h6>
                  </div>
                </div>
                <div>
                  <h6>Question : </h6>
                  <MathJax math={qs.questionaire.question} />
                </div>

                {qs.questionaire.choices.map((ch) => (
                  <div
                    className="d-flex flex-row"
                    style={{ alignItems: "center" }}
                  >
                    {ch.isCorrect ? (
                      <div
                        className="d-flex flex-row"
                        style={{
                          border: "2px solid green",
                          flexDirection: "row",
                          display: "flex",
                          paddingRight: "8px",
                          paddingLeft: "8px",
                          justifyContent: "center",
                        }}
                      >
                        <h6>{ch.label} : </h6> <MathJax math={ch.value} />
                      </div>
                    ) : (
                      <div
                        className="d-flex flex-row"
                        style={{
                          flexDirection: "row",
                          display: "flex",
                          paddingRight: "8px",
                          paddingLeft: "8px",
                          justifyContent: "center",
                        }}
                      >
                        <h6>{ch.label} : </h6> <MathJax math={ch.value} />
                      </div>
                    )}
                  </div>
                ))}

                <div>
                  <h6>Solution : </h6>
                  <MathJax math={qs.questionaire.solution} />
                </div>
                <div>
                  <h6>Hints :</h6>
                  {qs.isHintAvailable &&
                    qs.hints.map((ht, i) => (
                      <h6 style={{ flexDirection: "row", display: "flex" }}>
                        {i + 1}. <MathJax math={ht} />
                      </h6>
                    ))}
                </div>
              </div>
            );
          }
        })
      ) : (
        <div>
          <h6>Filter questions in the dashboard</h6>
        </div>
      )}
    </div>
  );
};

export default UnitQuestionsPdf;
