import axios from "axios";
import { baseUrl } from "../../config/baseUrl";
import { setAlert, removeAlert } from "../actions/Alert";
import { SET_ALERT, REMOVE_ALERT } from "../actions/Alert";
export const GET_UNITS_BY_SUBJECT_REQUEST = "GET_UNITS_BY_SUBJECT_REQUEST";
export const GET_UNITS_BY_SUBJECT_FAILURE = "GET_UNITS_BY_SUBJECT_FAILURE";
export const GET_UNITS_BY_SUBJECT_SUCCESS = "GET_UNITS_BY_SUBJECT_SUCCESS";

export const CREATE_UNITS_REQUEST = "CREATE_UNITS_REQUEST";
export const CREATE_UNITS_FAILURE = "CREATE_UNITS_FAILURE";
export const CREATE_UNITS_SUCCESS = "CREATE_UNITS_SUCCESS";

export const UPDATE_UNIT_REQUEST = "UPDATE_UNIT_REQUEST";
export const UPDATE_UNIT_FAILURE = "UPDATE_UNIT_FAILURE";
export const UPDATE_UNIT_SUCCESS = "UPDATE_UNIT_SUCCESS";

export const getUnitsBySubject = (subjectId) => async (dispatch) => {
  const token = localStorage.getItem("token");
  dispatch({
    type: GET_UNITS_BY_SUBJECT_REQUEST,
  });
  if (token) {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const res = await axios.get(
        `${baseUrl}/api/unit/subject/${subjectId}`,
        config
      );
      console.log(res.data);
      if (res.status === 200) {
        dispatch({
          type: GET_UNITS_BY_SUBJECT_SUCCESS,
          payload: res.data.units,
        });
      } else {
        dispatch({
          type: GET_UNITS_BY_SUBJECT_FAILURE,
          payload: res.data.error,
        });
      }
    } catch (err) {
      dispatch({
        type: GET_UNITS_BY_SUBJECT_FAILURE,
        payload: err,
      });
    }
  }
};

export const createUnits = ({ subjectId, units }) => async (dispatch) => {
  const token = localStorage.getItem("token");
  dispatch({
    type: CREATE_UNITS_REQUEST,
  });
  if (token) {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const body = JSON.stringify({ subjectId, units });
    try {
      const res = await axios.post(
        `${baseUrl}/api/unit/multiple-upload`,
        body,
        config
      );

      if (res.status === 200) {
        dispatch({
          type: CREATE_UNITS_SUCCESS,
          payload: res.data.concepts,
        });
        dispatch({
          type: SET_ALERT,
          payload: { message: res.data.message, alertType: "success" },
        });
        setTimeout(() => {
          dispatch({
            type: REMOVE_ALERT,
          });
        }, 5000);
      } else {
        dispatch({
          type: SET_ALERT,
          payload: { message: res.data.error, alertType: "error" },
        });
        setTimeout(() => {
          dispatch({
            type: REMOVE_ALERT,
          });
        }, 5000);
        dispatch({
          type: CREATE_UNITS_FAILURE,
          payload: res.data.error,
        });
      }
    } catch (err) {
      dispatch({
        type: SET_ALERT,
        payload: { message: err, alertType: "error" },
      });
      setTimeout(() => {
        dispatch({
          type: REMOVE_ALERT,
        });
      }, 5000);
      dispatch({
        type: CREATE_UNITS_FAILURE,
        payload: err,
      });
    }
  }
};

export const updateUnit = ({
  _id,
  name,
  number,
  sequence,
  imageUrl,
  description,
  isPublished,
}) => async (dispatch) => {
  const token = localStorage.getItem("token");
  dispatch({
    type: UPDATE_UNIT_REQUEST,
  });
  if (token) {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const body = JSON.stringify({
      name,
      number,
      sequence,
      description,
      imageUrl,
      isPublished,
    });
    
    try {
      const res = await axios.put(
        `${baseUrl}/api/unit/update/${_id}`,
        body,
        config
      );
      console.log(res.data);
      if (res.status === 200) {
        dispatch({
          type: UPDATE_UNIT_SUCCESS,
          payload: res.data.message,
        });
      } else {
        dispatch({
          type: UPDATE_UNIT_FAILURE,
          payload: res.data.error,
        });
      }
    } catch (err) {
      dispatch({
        type: UPDATE_UNIT_FAILURE,
        payload: "Request Failed",
      });
    }
  }
};
