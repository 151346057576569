import React, { useEffect, useState } from "react";

import axios from "axios";
import { baseUrl } from "../config/baseUrl";
import Drawer from "../components/Drawer";
import Alert from "../components/Alert";
import { Switch } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { Button, Typography, Avatar } from "@material-ui/core";
import { updateUnit } from "../store/actions/Unit";
const AddUnits = (props) => {
  const dispatch = useDispatch();
  const { _id, name, number, sequence, description, isPublished, imageUrl } =
    props.location.data;
  const [unitName, setUnitName] = useState(name ? name : "");
  const [unitNumber, setUnitNumber] = useState(number ? number : "");
  const [unitSequence, setUnitSequence] = useState(sequence ? sequence : "");
  const [unitDescription, setUnitDescription] = useState(
    description ? description : ""
  );
  const [isUnitPublished, setIsUnitPublished] = useState(
    isPublished ? isPublished : false
  );
  const [unitImageUrl, setUnitImageUrl] = useState(imageUrl ? imageUrl : "");

  const [unitError, setUnitError] = useState("");

  const alert = useSelector((state) => state.alert.alert);
  const user = useSelector((state) => state.auth.user);
  const account = useSelector((state) => state.account.account);

  const row = { display: "flex", flexDirection: "row", alignContent: "center" };

  useEffect(() => {}, []);
  if (alert !== null && alert.alertType == "success") {
    props.history.goBack();
  }

  const fileChangeHandler = async (event) => {
    //setSelectedFile(event.target.files[0]);
    // console.log(event.target.files[0]);
    const token = localStorage.getItem("token");
    if (token) {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: token,
          Accept: "*",
        },
      };

      const formData = new FormData();
      formData.append("imageFile", event.target.files[0]);
      formData.append("name", event.target.files[0].name);
      try {
        console.log("trying upload");

        const res = await axios.post(
          `${baseUrl}/api/image-file`,
          formData,
          config
        );

        setUnitImageUrl(res.data.imageLocation);
      } catch (err) {
        console.log(err);
      }
    }
  };
  // validation

  const validate = () => {
    if (unitName == "") {
      setUnitError("Unit Name is required");
      return false;
    }
    if (unitNumber == "") {
      setUnitError("Unit Number is required");
      return false;
    }
    if (unitSequence == "") {
      setUnitError("Unit Sequence is required");
      return false;
    }

    return true;
  };
  const onClickTocreateSubunits = () => {
    const isValid = validate();
    console.log("save units");
    if (isValid) {
      dispatch(
        updateUnit({
          _id,
          name: unitName,
          number: unitNumber,
          sequence: unitSequence,
          description: unitDescription,
          imageUrl: unitImageUrl,
          isPublished: isUnitPublished,
        })
      );
    }
  };
  return (
    <div>
      <Drawer
        headerName="Update Unit"
        user={account !== null && account.firstName}
      >
        {alert && <Alert severity={alert.alertType} message={alert.message} />}
        {unitError && (
          <div style={{ padding: 8, backgroundColor: "red" }}>
            <Typography variant="body1">{unitError}</Typography>
          </div>
        )}
        <div style={{ width: "100%" }}>
          <div>
            <input
              style={{ margin: 8 }}
              type="text"
              placeholder="Unit Number"
              onChange={(e) => setUnitNumber(e.target.value)}
              name="number"
              value={unitNumber}
              required
            />

            <input
              style={{ margin: 8 }}
              type="number"
              placeholder="Unit Sequence"
              onChange={(e) => setUnitSequence(e.target.value)}
              name="sequence"
              value={unitSequence}
              required
            />

            <input
              style={{ margin: 8 }}
              type="text"
              placeholder="Name"
              onChange={(e) => setUnitName(e.target.value)}
              name="name"
              value={unitName}
            />
            <div style={row}>
              <Typography variant="h5" component="h5">
                Published
              </Typography>
              <Switch
                checked={isUnitPublished}
                onChange={() => setIsUnitPublished(!isUnitPublished)}
                name="checked"
                inputProps={{ "aria-label": "secondary checkbox" }}
              />
            </div>
            <input
              style={{ margin: 8, width: 300 }}
              type="text"
              placeholder="Description"
              onChange={(e) => setUnitDescription(e.target.value)}
              name="description"
              value={unitDescription}
            />
            <div style={row}>
              <input
                style={{ margin: 8 }}
                type="file"
                name="myImage"
                onChange={fileChangeHandler}
              />
              {unitImageUrl && (
                <img src={unitImageUrl} style={{ width: 40, height: 40 }} />
              )}
            </div>
            <div
              style={{
                display: "flex",
                margin: 8,
              }}
            >
              <Button
                size="small"
                variant="contained"
                color="primary"
                disableElevation
                onClick={onClickTocreateSubunits}
              >
                Update
              </Button>
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default AddUnits;
