//import isEmpty from "../../validation/is-empty";
import {
  GET_SUBUNITS_BY_UNIT_SUCCESS,
  GET_SUBUNITS_BY_UNIT_FAILURE,
  GET_SUBUNITS_BY_UNIT_REQUEST,
  CREATE_SUBUNITS_FAILURE,
  CREATE_SUBUNITS_REQUEST,
  CREATE_SUBUNITS_SUCCESS,
} from "../actions/Subunit";

const initialState = {
  subunits: [],
  message: null,
  error: null,
  isLoading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CREATE_SUBUNITS_REQUEST:
    case GET_SUBUNITS_BY_UNIT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case CREATE_SUBUNITS_SUCCESS:
    case GET_SUBUNITS_BY_UNIT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        subunits: action.payload,
      };
    case CREATE_SUBUNITS_FAILURE:
    case GET_SUBUNITS_BY_UNIT_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
