import React, { useEffect, useState } from "react";

import axios from "axios";
import { baseUrl } from "../config/baseUrl";
import Drawer from "../components/Drawer";
import Alert from "../components/Alert";
import { Switch } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { Button, Typography, Avatar } from "@material-ui/core";
import { uploadSubjectImage } from "../store/actions/Subject";
const UpdateSubject = (props) => {
  const dispatch = useDispatch();
  const {
    _id,
    name,

    description,
    isPublished,
    imageUrl,
  } = props.location.data;
  const [subjectName, setSubjectName] = useState(name ? name : "");
  const [subjectDescription, setSubjectDescription] = useState(
    description ? description : ""
  );
  const [isUnitPublished, setIsUnitPublished] = useState(
    isPublished ? isPublished : false
  );
  const [subjectImageUrl, setSubjectImageUrl] = useState("");

  const [subjectError, setSubjectError] = useState("");

  const alert = useSelector((state) => state.alert.alert);
  const user = useSelector((state) => state.auth.user);
  const account = useSelector((state) => state.account.account);

  const row = { display: "flex", flexDirection: "row", alignContent: "center" };

  useEffect(() => {}, []);
  if (alert !== null && alert.alertType == "success") {
    props.history.goBack();
  }

  const fileChangeHandler = async (event) => {
    //setSelectedFile(event.target.files[0]);
    // console.log(event.target.files[0]);
    const token = localStorage.getItem("token");
    if (token) {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: token,
          Accept: "*",
        },
      };

      const formData = new FormData();
      formData.append("imageFile", event.target.files[0]);
      formData.append("name", event.target.files[0].name);
      try {
        console.log("trying upload");

        const res = await axios.post(
          `${baseUrl}/api/image-file`,
          formData,
          config
        );

        setSubjectImageUrl(res.data.imageLocation);
      } catch (err) {
        console.log(err);
      }
    }
  };
  // validation

  const validate = () => {
    // if (subjectName == "") {
    //   setSubjectError("Unit Name is required");
    //   return false;
    // }
    if (subjectImageUrl == "") {
      setSubjectError("Uplaod Image");
      return false;
    }
    return true;
  };
  const onClickToUploadImage = () => {
    const isValid = validate();

    if (isValid) {
      dispatch(
        uploadSubjectImage({
          subjectId: _id,
          imageUrl: subjectImageUrl,
        })
      );
    }
  };
  return (
    <div>
      <Drawer
        headerName="Update Unit"
        user={account !== null && account.firstName}
      >
        {alert && <Alert severity={alert.alertType} message={alert.message} />}
        {subjectError && (
          <div style={{ padding: 8, backgroundColor: "red" }}>
            <Typography variant="body1">{subjectError}</Typography>
          </div>
        )}
        <div style={{ width: "100%" }}>
          <div>
            {/* <input
              style={{ margin: 8 }}
              type="text"
              placeholder="Name"
              onChange={(e) => setSubjectName(e.target.value)}
              name="name"
              value={subjectName}
            />
            <div style={row}>
              <Typography variant="h5" component="h5">
                Published
              </Typography>
              <Switch
                checked={isUnitPublished}
                onChange={() => setIsUnitPublished(!isUnitPublished)}
                name="checked"
                inputProps={{ "aria-label": "secondary checkbox" }}
              />
            </div>
            <input
              style={{ margin: 8, width: 300 }}
              type="text"
              placeholder="Description"
              onChange={(e) => setSubjectDescription(e.target.value)}
              name="description"
              value={subjectDescription}
            /> */}
            <div style={row}>
              <input
                style={{ margin: 8 }}
                type="file"
                name="myImage"
                onChange={fileChangeHandler}
              />
              {subjectImageUrl && (
                <img src={subjectImageUrl} style={{ width: 40, height: 40 }} />
              )}
            </div>
            <div
              style={{
                display: "flex",
                margin: 8,
              }}
            >
              <Button
                size="small"
                variant="contained"
                color="primary"
                disableElevation
                onClick={onClickToUploadImage}
              >
                Update
              </Button>
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default UpdateSubject;
