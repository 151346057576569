import axios from "axios";
import setAuthToken from "../../utils/setAuthToken";
import jwt_decode from "jwt-decode";

import { baseUrl } from "../../config/baseUrl";
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGOUT = "LOGOUT";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";

export const LOAD_USER_REQUEST = "LOAD_USER_REQUEST";
export const LOAD_USER_SUCCESS = "LOAD_USER_SUCCESS";
export const LOAD_USER_FAILURE = "LOAD_USER_FAILURE";
// loaduser
export const loadUser = () => async (dispatch) => {
  const token = localStorage.getItem("token");
  dispatch({
    type: LOAD_USER_REQUEST,
  });
  //console.log("load user call" + token);
  if (token) {
    const token = localStorage.getItem("token");
    const decoded = jwt_decode(token);
    // console.log("decoded " + decoded);
    if (decoded.exp * 1000 > Date.now()) {
      const data = {
        user: decoded,
        userId: decoded.id,
        role: decoded.role,
        unitId: decoded.unitId,
      };
      dispatch({
        type: LOAD_USER_SUCCESS,
        payload: data,
      });
    } else {
      dispatch({
        type: LOAD_USER_FAILURE,
      });
    }
  } else {
    console.log("not token found");
    dispatch({
      type: LOAD_USER_FAILURE,
    });
  }
};

// Login - Get User Token
export const loginUser = ({ email, password }) => (dispatch) => {
  console.log("login credentals" + email, password);
  const config = {
    headers: {
      //authorization: "JWT_TOKEN",
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  const body = JSON.stringify({ email, password });
  axios
    .post(baseUrl + "/api/login", body, config)
    .then((res) => {
      console.log("res" + JSON.stringify(res));

      if (res.status === 200) {
        const decoded = jwt_decode(res.data.token);
        localStorage.setItem("token", res.data.token);
        setAuthToken(res.data.token);
        const data = {
          message: res.data.message,
          token: res.data.token,
          user: decoded,
        };
        dispatch({
          type: LOGIN_SUCCESS,
          payload: data,
        });
      } else {
        dispatch({
          type: LOGIN_FAILURE,
          payload: res.data.message,
        });
      }
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: LOGIN_FAILURE,
        payload: err.response.data.message,
      });
    });
};

// Log user out
export const logout = () => (dispatch) => {
  localStorage.removeItem("token");
  dispatch({
    type: LOGOUT,
  });
};
