import axios from "axios";
import { baseUrl } from "../../config/baseUrl";
import { setAlert, removeAlert } from "../actions/Alert";
import { SET_ALERT, REMOVE_ALERT } from "../actions/Alert";
export const CREATE_USER_REQUEST = "CREATE_USER_REQUEST";
export const CREATE_USER_FAILURE = "CREATE_USER_FAILURE";
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";

export const GET_USERS_REQUEST = "GET_USERS_REQUEST";
export const GET_USERS_FAILURE = "GET_USERS_FAILURE";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";

export const ASSIGN_WORK_REQUEST = "ASSIGN_WORK_REQUEST";
export const ASSIGN_WORK_FAILURE = "ASSIGN_WORK_FAILURE";
export const ASSIGN_WORK_SUCCESS = "ASSIGN_WORK_SUCCESS";

export const createUser = (userData) => async (dispatch) => {
  const token = localStorage.getItem("token");
  dispatch({
    type: CREATE_USER_REQUEST,
  });
  if (token) {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const body = JSON.stringify(userData);
    try {
      const res = await axios.post(`${baseUrl}/api/user/new`, body, config);

      if (res.status === 200) {
        dispatch({
          type: CREATE_USER_SUCCESS,
          payload: res.data.message,
        });
        dispatch({
          type: SET_ALERT,
          payload: { message: res.data.message, alertType: "success" },
        });
        setTimeout(() => {
          dispatch({
            type: REMOVE_ALERT,
          });
        }, 5000);
      } else {
        dispatch({
          type: CREATE_USER_FAILURE,
          payload: res.data.error,
        });
      }
    } catch (err) {
      dispatch({
        type: SET_ALERT,
        payload: { message: err, alertType: "error" },
      });
      setTimeout(() => {
        dispatch({
          type: REMOVE_ALERT,
        });
      }, 5000);
      dispatch({
        type: CREATE_USER_FAILURE,
        payload: err,
      });
    }
  }
};

export const getUsers = () => async (dispatch) => {
  const token = localStorage.getItem("token");
  dispatch({
    type: GET_USERS_REQUEST,
  });
  if (token) {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const res = await axios.get(`${baseUrl}/api/user/all-users`, config);
      console.log("users" + JSON.stringify(res));
      if (res.status === 200) {
        dispatch({
          type: GET_USERS_SUCCESS,
          payload: res.data.users,
        });
      } else {
        dispatch({
          type: GET_USERS_FAILURE,
          payload: res.data.error,
        });
      }
    } catch (err) {
      dispatch({
        type: GET_USERS_FAILURE,
        payload: err,
      });
    }
  }
};

export const assignContent = (userData) => async (dispatch) => {
  const token = localStorage.getItem("token");
  dispatch({
    type: ASSIGN_WORK_REQUEST,
  });
  if (token) {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const body = JSON.stringify(userData);
    try {
      const res = await axios.post(
        `${baseUrl}/api/user/assign-content`,
        body,
        config
      );
      console.log("users" + JSON.stringify(res));
      if (res.status === 200) {
        dispatch({
          type: ASSIGN_WORK_SUCCESS,
          payload: res.data.users,
        });
        dispatch({
          type: SET_ALERT,
          payload: { message: res.data.message, alertType: "success" },
        });
        setTimeout(() => {
          dispatch({
            type: REMOVE_ALERT,
          });
        }, 5000);
      } else {
        dispatch({
          type: SET_ALERT,
          payload: { message: res.data.error, alertType: "error" },
        });
        setTimeout(() => {
          dispatch({
            type: REMOVE_ALERT,
          });
        }, 5000);
        dispatch({
          type: ASSIGN_WORK_FAILURE,
          payload: res.data.error,
        });
      }
    } catch (err) {
      dispatch({
        type: SET_ALERT,
        payload: { message: err, alertType: "error" },
      });
      setTimeout(() => {
        dispatch({
          type: REMOVE_ALERT,
        });
      }, 5000);
      dispatch({
        type: ASSIGN_WORK_FAILURE,
        payload: err,
      });
    }
  }
};
