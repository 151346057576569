import React, { useEffect, useState } from "react";
import axios from "axios";
import { baseUrl } from "../config/baseUrl";
import { useSelector, useDispatch } from "react-redux";
import Drawer from "../components/Drawer";
import Mathjax from "../components/Mathjax";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    // minWidth: 400,
    // maxWidth: 600,
    marginBottom: 2,
  },
  info: {
    // minWidth: 400,
    maxWidth: 600,
    marginBottom: 2,
  },
  cardcontent: {
    // padding: 0,
    "&:last-child": {
      paddingBottom: 16,
    },
  },
});

const VerifyQuestion = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const account = useSelector((state) => state.account.account);
  const [page, setPage] = useState(0);
  const [question, setQuestion] = useState({});
  const [error, setError] = useState("");
  useEffect(() => {
    getQuestion(0);
  }, [dispatch]);
  const row = {
    display: "flex",
    flexDirection: "row",
    alignContent: "center",
    alignItems: "center",
    textAlign: "center",
  };
  // console.log(question);
  const goToNext = () => {
    getQuestion(page + 1);
    setPage(page + 1);
  };
  const goToPrev = () => {
    if (page !== 0) {
      getQuestion(page - 1);
      setPage(page - 1);
    }
  };
  const onClickUpdateQuestion = (data) => {
    //console.log(rowData);
    props.history.push({
      pathname: "/update-question",
      data: data,
    });
  };

  const clickToVerify = async (questionId) => {
    const token = localStorage.getItem("token");
    if (token) {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const res = await axios.put(
        `${baseUrl}/api/question/verify/${questionId}`,
        config
      );
      console.log(res);
      if (res.status === 200) {
        goToNext();
      } else {
        setError(res.data.message);
      }
    }
  };
  // console.log(page);
  const getQuestion = async (p) => {
    console.log(p);
    // setQuestion({});
    const token = localStorage.getItem("token");

    if (token) {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const res = await axios.get(
        `${baseUrl}/api/question/unit/${account.unit._id}/${p}`,
        config
      );

      if (res.status === 200) {
        // console.log(res.data);
        if (res.data.question == null) {
          setError(res.data.message);
          return;
        }

        setQuestion(res.data.question);
      } else {
        setError(res.data.message);
      }
    }
  };

  return (
    <div>
      <Drawer headerName="Verify Question">
        <div
          style={{
            maxWidth: "100%",
          }}
        >
          <div className="container">
            <div
              style={{
                alignContent: "center",
                justifyContent: "center",
                display: "flex",
                // minWidth: 400,
                // maxWidth: 600,
              }}
            >
              <div
                style={{
                  minWidth: 600,
                  maxWidth: 600,
                }}
              >
                {error == "" ? (
                  question._id ? (
                    <div>
                      <Card className={classes.root} variant="outlined">
                        <CardContent className={classes.cardcontent}>
                          <div>
                            <Typography>Q.{question.questionNumber}</Typography>{" "}
                            <Mathjax math={question.questionaire.question} />
                          </div>
                        </CardContent>
                      </Card>
                      {question.questionaire.choices.map((ch) => (
                        <Card className={classes.root} variant="outlined">
                          <CardContent className={classes.cardcontent}>
                            <div style={{ ...row }}>
                              <div
                                style={{
                                  width: 40,
                                  height: 40,
                                  borderRadius: 40,
                                  backgroundColor: ch.isCorrect
                                    ? "lightgreen"
                                    : "lightgrey",
                                  alignContent: "center",
                                  textAlign: "center",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  alignSelf: "center",
                                  display: "flex",
                                }}
                              >
                                {ch.label}
                              </div>
                              <div
                                style={{
                                  marginLeft: 16,
                                }}
                              >
                                <Mathjax math={ch.value} />
                              </div>
                            </div>
                          </CardContent>
                        </Card>
                      ))}
                      <Card className={classes.root} variant="outlined">
                        <CardContent className={classes.cardcontent}>
                          <Typography variant="h5" component="h2">
                            Solution
                          </Typography>
                          <Mathjax math={question.questionaire.solution} />
                        </CardContent>
                      </Card>
                      {question.isHintAvailable &&
                        question.hints.map((h) => (
                          <Card className={classes.root} variant="outlined">
                            <CardContent>
                            
                              <Mathjax math={h} />
                            </CardContent>
                          </Card>
                        ))}
                    </div>
                  ) : (
                    <div style={{ minWidth: 400 }}>
                      <p>Loading</p>
                    </div>
                  )
                ) : (
                  <div>
                    <Typography variant="body2" component="p">
                      No Question found
                    </Typography>
                  </div>
                )}
              </div>
              <div style={{
             
                minWidth: 400,
                maxWidth: 400,
              }}>
                {error == "" && question._id && (
                  <Card className={classes.info} variant="outlined">
                    <CardContent>
                      <Typography variant="body1" component="p">
                        Info
                      </Typography>
                      <Typography variant="body2" component="p">
                        Class :{account.standard.name}
                      </Typography>
                      <Typography variant="body2" component="p">
                        Subject : {account.subject.number}.
                        {account.subject.name}
                      </Typography>
                      <Typography variant="body2" component="p">
                        Unit : {question.unit.number}.{question.unit.name}
                      </Typography>
                      <Typography variant="body2" component="p">
                        Sub unit : {question.subunit.number}.
                        {question.subunit.name}
                      </Typography>
                      <Typography variant="body2" component="p">
                        Concept : {question.concept.number}.
                        {question.concept.name}
                      </Typography>
                      <Typography variant="body2" component="p">
                        ObjectiveType : {question.objectiveType.name}
                      </Typography>
                      <Typography variant="body2" component="p">
                        Difficulty Level : {question.difficultyLevel.name}
                      </Typography>
                    </CardContent>
                  </Card>
                )}
                <div
                  style={{
                    flexDirection: "column",
                    display: "flex",
                    alignContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Button
                    style={{ width: 250, margin: 8 }}
                    variant="contained"
                    color="primary"
                    onClick={() => clickToVerify(question._id)}
                  >
                    Verify
                  </Button>
                  <Button
                    style={{ width: 250, margin: 8 }}
                    variant="contained"
                    // color="secondary"
                    onClick={() => onClickUpdateQuestion(question)}
                  >
                    Update
                  </Button>
                  <div
                    style={{
                      justifyContent: "space-between",
                      alignItems: "center",
                      display: "flex",
                      // maxWidth: 600,
                      width: 250,
                      marginTop: 16,
                    }}
                  >
                    <Button startIcon={<ArrowBackIosIcon />} onClick={goToPrev}>
                      Prev
                    </Button>
                    <div></div>
                    <Button
                      endIcon={<ArrowForwardIosIcon />}
                      onClick={goToNext}
                    >
                      Next
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default VerifyQuestion;
