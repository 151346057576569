import {
  GET_SUBUNITS_FAILURE,
  GET_SUBUNITS_SUCCESS,
  GET_SUBUNITS_REQUEST,
  GET_SUBJECTS_FAILURE,
  GET_SUBJECTS_REQUEST,
  GET_SUBJECTS_SUCCESS,
  GET_UNITS_FAILURE,
  GET_UNITS_REQUEST,
  GET_UNITS_SUCCESS,
  GET_CONCEPTS_BY_SUBUNIT_SUCCESS,
  GET_CONCEPTS_BY_SUBUNIT_REQUEST,
  GET_CONCEPTS_BY_SUBUNIT_FAILURE,
  GET_OBJECTIVE_TYPES_FAILURE,
  GET_OBJECTIVE_TYPES_SUCCESS,
  GET_OBJECTIVE_TYPES_REQUEST,
  GET_DIFFICULTY_LEVELS_REQUEST,
  GET_DIFFICULTY_LEVELS_FAILURE,
  GET_DIFFICULTY_LEVELS_SUCCESS,
  CREATE_QUESTION_FAILURE,
  CREATE_QUESTION_SUCCESS,
  CREATE_QUESTION_REQUEST,
  UPDATE_QUESTION_FAILURE,
  UPDATE_QUESTION_REQUEST,
  UPDATE_QUESTION_SUCCESS,
  DELETE_QUESTION_FAILURE,
  DELETE_QUESTION_REQUEST,
  DELETE_QUESTION_SUCCESS,
  GET_QUESTIONS_FAILURE,
  GET_QUESTIONS_REQUEST,
  GET_QUESTIONS_SUCCESS,
  GET_QUESTIONS_BY_UNIT_ID_SUCCESS,
  GET_QUESTIONS_BY_UNIT_ID_FAILURE,
  GET_QUESTIONS_BY_UNIT_ID_REQUEST,
  ASSIGN_UNIT_FAILURE,
  ASSIGN_UNIT_REQUEST,
  ASSIGN_UNIT_SUCCESS,
} from "../actions/Content";

const initialState = {
  subjects: [],
  units: [],
  subunits: [],
  concepts: [],
  questions: [],
  difficultyLevels: [],
  objectiveTypes: [],
  message: null,
  error: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_DIFFICULTY_LEVELS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case GET_DIFFICULTY_LEVELS_SUCCESS:
      return {
        ...state,
        difficultyLevels: action.payload,
        isLoading: false,
      };

    case GET_DIFFICULTY_LEVELS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case GET_OBJECTIVE_TYPES_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case GET_OBJECTIVE_TYPES_SUCCESS:
      return {
        ...state,
        objectiveTypes: action.payload,
        isLoading: false,
      };

    case GET_OBJECTIVE_TYPES_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    case GET_CONCEPTS_BY_SUBUNIT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case GET_CONCEPTS_BY_SUBUNIT_SUCCESS:
      return {
        ...state,
        concepts: action.payload,
        isLoading: false,
      };
    case GET_SUBJECTS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case GET_SUBJECTS_SUCCESS:
      return {
        ...state,
        subjects: action.payload,
        isLoading: false,
      };

    case GET_SUBJECTS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case GET_CONCEPTS_BY_SUBUNIT_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case GET_UNITS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case GET_UNITS_SUCCESS:
      return {
        ...state,
        units: action.payload,
        isLoading: false,
      };

    case GET_UNITS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case GET_SUBUNITS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case GET_SUBUNITS_SUCCESS:
      return {
        ...state,
        subunits: action.payload,
        isLoading: false,
      };

    case GET_SUBUNITS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case CREATE_QUESTION_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case CREATE_QUESTION_SUCCESS:
      return {
        ...state,
        message: action.payload,
        isLoading: false,
      };

    case CREATE_QUESTION_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case ASSIGN_UNIT_REQUEST:
    case UPDATE_QUESTION_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ASSIGN_UNIT_SUCCESS:
    case UPDATE_QUESTION_SUCCESS:
      return {
        ...state,
        message: action.payload,
        isLoading: false,
      };
    case ASSIGN_UNIT_FAILURE:
    case UPDATE_QUESTION_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case DELETE_QUESTION_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case DELETE_QUESTION_SUCCESS:
      return {
        ...state,
        message: action.payload,
        isLoading: false,
      };

    case DELETE_QUESTION_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case GET_QUESTIONS_REQUEST:
    case GET_QUESTIONS_BY_UNIT_ID_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case GET_QUESTIONS_SUCCESS:
    case GET_QUESTIONS_BY_UNIT_ID_SUCCESS:
      return {
        ...state,
        questions: action.payload,
        isLoading: false,
      };

    case GET_QUESTIONS_FAILURE:
    case GET_QUESTIONS_BY_UNIT_ID_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    default:
      return state;
  }
}
